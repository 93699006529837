/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable prettier/prettier */
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children}) => {
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  if (
    token === null ||
    token === "null" ||
    token === undefined ||
    token === "undefined" ||
    token === ""
  ) {
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return <Navigate to={`/signin/${type != null && type != undefined && type != "" ? type : "amin"}`}/>;
  } else {
    return children
  }
};

export default PrivateRoute;
