/* eslint-disable prettier/prettier */
import React, { useCallback } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import UsersAPI from "api/users";
import { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Icon,
  Switch,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import getRoles from "utilities/getRoles";
import MDButton from "components/MDButton";
import moment from "moment";
import CreateUser from "examples/CreateUser";
import AddBalance from "examples/AddBalance";
import BalanceAPI from "api/balance";
import { setUserBalance, useMaterialUIController } from "context";
import MDSnackbar from "components/MDSnackbar";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";

const Users = () => {
  const [controller, dispatch] = useMaterialUIController();
  const service = new UsersAPI();
  const balanceService = new BalanceAPI();
  const authUser = JSON.parse(localStorage.getItem("user"));
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [addBalanceRow, setAddBalanceRow] = useState(null);
  const [addBalanceNumber, setAddBalanceNumber] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [searchUserName, setSearchUserName] = useState("");

  const rows = getAllUsers;

  const handleStatusChange = async (rowData, checked) => {
    setIsLoading(true);
    const payload = {
      isActive: !checked,
      id: rowData?.id
    };
    const resp = await service.StatusChange(payload);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("user");
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
      });
    }
  }

  const columns = [
    {
      Header: "Status",
      accessor: (rowData) => {
        return (
          <Switch checked={rowData?.isActive == 1 && rowData?.createdByUser?.isActive == 1 ? true : false} onChange={(e) => {
            if (rowData?.createdByUser?.isActive == 1) {
              if (authUser?.role?.name == "Master Super Admin" || authUser?.role?.name == "SuperAdmin") {
                Swal.fire({
                  title: `User ${e.target.checked ? "Active" : "Inactive"}`,
                  text: `You want to ${e.target.checked ? "Active" : "Inactive"} this file?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#1A73E8",
                  cancelButtonColor: "#d33",
                  confirmButtonText: `Yes, ${e.target.checked ? "Active" : "Inactive"} it!`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleStatusChange(rowData, e.target.checked)
                  }
                });
              }
            }
          }
          } />
        );
      },
      align: "center",
    },
    {
      Header: "role",
      accessor: "role.name",
      align: "center",
    },
    {
      Header: "User Name",
      accessor: (rowData) => {
        return (
          <Tooltip title={`Password: ${rowData?.password}`} placement="top">
            <p>{rowData?.username}</p>
          </Tooltip>
        );
      },
      align: "center",
    },
    {
      Header: "Creation Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "Parent",
      accessor: "createdByUser.username",
      align: "center",
    },
    {
      Header: "logo",
      accessor: (rowData) => {
        return rowData?.logo != null && rowData?.logo != undefined && rowData?.logo != "" ? (
          <img src={rowData?.logo} height={25} width={25} alt="" />
        ) : (
          ""
        );
      },
      align: "center",
    },
    {
      Header: "Action",
      accessor: (rowData) =>
        authUser?.role?.name == "SuperAdmin" ? (
          <Icon fontSize="small" onClick={() => handleEdit(rowData)}>
            edit
          </Icon>
        ) : (
          <Tooltip title={"Kindly Contact Your Admin"} placement="top">
            <Icon fontSize="small">edit</Icon>
          </Tooltip>
        ),
      align: "center",
    },
  ];

  useEffect(() => {
    (async () => {
      getAllUsersList("user");
    })();
  }, [page, rowsPerPage]);

  useEffect(() => {
    (async () => {
      const roles = await getRoles();
      setRoles(roles);
    })();
  }, []);

  const getAllUsersList = async (type = "", search = "") => {
    setIsLoading(true);
    const payload = {
      search: search,
      type: type,
      page: page + 1,
      pageSize: rowsPerPage,
    };
    const resp = await service.GetAllUsers(payload);
    if (resp?.data?.data?.users?.results && resp?.data?.info?.isSuccess == true) {
      setGetAllUsers(resp?.data?.data?.users?.results);
      setTotalUsers(resp?.data?.data?.users?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitUser = async () => {
    setIsLoading(true);
    let adminRoleId = "";
    roles?.filter((item) => {
      if (item?.name == selectedRole) {
        adminRoleId = item?.id;
      }
    });
    const type = JSON.parse(localStorage.getItem("user"));
    const payload = {
      // name: name,
      username: userName,
      password: "password",
      roleId: adminRoleId,
      type: selectedRole == "CheckerUser" ? "checker" : "user",
      logo: uploadedFile,
    };
    const fm = new FormData();

    for (const key in payload) {
      fm.append(key, payload[key]);
    }
    const resp = await service.CreateUser(fm);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("user");
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenCreateModal(false);
      setName("");
      setSelectedRole("");
      setUploadedFile(null);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const getUserBalance = async () => {
    setIsLoading(true);
    const resp = await balanceService.GetUserBalance();
    if (resp?.data?.data?.balance && resp?.data?.info?.isSuccess == true) {
      setUserBalance(dispatch, resp?.data?.data?.balance);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitAddBalance = async () => {
    setIsLoading(true);
    const payload = {
      amount: Number(addBalanceNumber),
      userId: addBalanceRow?.id,
    };
    const resp = await balanceService.AddBalance(payload);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("user");
      getUserBalance();
      setIsOpenCreateModal(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setAddBalanceRow(null);
      setAddBalanceNumber("");
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const debouncedHandleChange = useCallback(
    debounce((e) => {
      getAllUsersList("user", e);
    }, 500),
    []
  );

  const TITLE = "Dmit Report | Users";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="USER"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <CreateUser
        setIsOpenCreateModal={setIsOpenCreateModal}
        isOpenCreateModal={isOpenCreateModal}
        handleSubmitUser={handleSubmitUser}
        setUserName={setUserName}
        setName={setName}
        name={name}
        userName={userName}
        tableTitle={"USER"}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        isLoading={isLoading}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
      />
      <AddBalance
        addBalanceRow={addBalanceRow}
        setAddBalanceRow={setAddBalanceRow}
        setAddBalanceNumber={setAddBalanceNumber}
        addBalanceNumber={addBalanceNumber}
        handleSubmitAddBalance={handleSubmitAddBalance}
        isLoading={isLoading}
      />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="table-header">
                    <MDTypography variant="h6" color="white">
                      Users
                    </MDTypography>
                    <div>
                      <TextField
                        id="outlined-basic"
                        className="header-filter-input"
                        value={searchUserName}
                        variant="outlined"
                        placeholder="Search User Name"
                        onChange={(e) => {
                          setSearchUserName(e.target.value);
                          debouncedHandleChange(e.target.value);
                        }}
                      />

                      {authUser?.role?.name == "Admin" ? (
                        <MDButton
                          variant="primary"
                          color="light"
                          size="small"
                          style={{ backgroundColor: "#fff" }}
                          onClick={() => setIsOpenCreateModal(true)}
                        >
                          Create
                        </MDButton>
                      ) : null}
                    </div>
                  </div>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                <TablePagination
                  component="div"
                  count={totalUsers}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Users;
