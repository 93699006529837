/* eslint-disable prettier/prettier */
import { Backdrop, CircularProgress } from "@mui/material";
import BalanceAPI from "api/balance";
import PricingAPI from "api/pricing";
import { useMaterialUIController } from "context";
import { setUserBalance } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const PaymentStatus = () => {
  const pricingService = new PricingAPI();
  const balanceService = new BalanceAPI();
  const [controller, dispatch] = useMaterialUIController();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [respData, setRespData] = useState({});

  useEffect(() => {
    (async () => {
      if (params?.id) {
        try {
          const resp = await pricingService.PaymentStatus({ id: params?.id });
          if (resp?.data?.info?.isSuccess) {
            setRespData(resp?.data?.data?.payment);
            getUserBalance()
          }
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
        }
      }
    })();
  }, [params]);

  const getUserBalance = async () => {
    setIsLoading(true);
    const resp = await balanceService.GetUserBalance();
    if (resp?.data?.data?.balance && resp?.data?.info?.isSuccess == true) {
      setUserBalance(dispatch, resp?.data?.data?.balance);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DashboardLayout>
        {isLoading ? (
          <Backdrop
            sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
        <div style={{ height: "100%", position: "relative", top: "10rem" }}>
          <div
            style={
              {
                // backgroundColor: "#f7fafc",
              }
            }
          >
            {!isLoading && (
              <div
                style={{
                  padding: "24px",
                  margin: "0 auto",
                  maxWidth: "768px",
                  borderRadius: "2rem",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  textAlign: "center",
                }}
              >
                {!isLoading && respData?.code == "PAYMENT_SUCCESS" ? (
                  <svg
                    viewBox="0 0 24 24"
                    style={{
                      color: "green",
                      fill: "#34d399",
                      width: "64px",
                      height: "70px",
                      margin: "0 auto 24px",
                      display: "block",
                    }}
                  >
                    <path
                      fill="currentColor"
                      d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                    ></path>
                  </svg>
                ) : (
                  <div
                    style={{
                      marginBottom: "2rem",
                    }}
                  ></div>
                )}

                {!isLoading && (
                  <div style={{ textAlign: "center" }}>
                    <h3
                      style={{
                        fontSize: "24px",
                        color: "#1f2937",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      {respData?.code == "PAYMENT_SUCCESS" ? (
                        <>Payment Done!</>
                      ) : (
                        <>Payment failed!</>
                      )}
                    </h3>
                    <p style={{ color: "#4b5563", marginTop: "8px" }}>
                      {respData?.code == "PAYMENT_SUCCESS" ? (
                        <>{`transactionId: ${respData?.data?.transactionId}`}</>
                      ) : null}
                    </p>
                    <p style={{ color: "#4b5563", marginTop: "8px" }}>
                      {respData?.code == "PAYMENT_SUCCESS" ? (
                        <>Thank you for completing your secure online payment.</>
                      ) : (
                        <>Your Payment was not Completed.</>
                      )}
                    </p>
                    <div></div>
                    <div style={{ paddingTop: "20px", textAlign: "center", cursor: "pointer" }}>
                      <a
                        onClick={() => navigate(`/dashboard`)}
                        style={{
                          backgroundColor: "#4f46e5",
                          color: "#ffffff",
                          fontWeight: "600",
                          padding: "12px 24px",
                          borderRadius: "4px",
                          textDecoration: "none",
                        }}
                      >
                        GO BACK
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default PaymentStatus;
