/* eslint-disable */
import axios from 'axios';
import { authRoutes } from 'constant/apiRoutes';

const apiClient = axios.create();

apiClient.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem('token');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    console.info('--------------------')
    console.info('error => ', error )
    console.info('--------------------')
    const originalRequest = error?.config;
    const refresh_token = localStorage.getItem('refresh_token')
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.info?.errors?.err_code == "TOKEN_EXPIRED"
    ) {
      try {
        const URL = authRoutes.REFRESH_TOKEN
        const headers = { 'Authorization': `Bearer ${refresh_token}`};
        const resp = await axios.get(URL, { headers }).then(res => {
          localStorage.setItem("user", JSON.stringify(res?.data?.data?.user));
          localStorage.setItem("token", res?.data?.data?.token);
          localStorage.setItem("refresh_token", res?.data?.data?.refresh_token);
          return res.data
        }).catch(err => {
          console.info('--------------------')
          console.info('err => ', err )
          console.info('--------------------')
          if (err?.response?.status === 401) {
            localStorage.clear()
            window.location.href = '/authentication/sign-in'
          }
          return err
        }) ;
        return apiClient(originalRequest);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
