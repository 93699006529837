/* eslint-disable prettier/prettier */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ForumIcon from '@mui/icons-material/Forum';
import AssessmentIcon from '@mui/icons-material/Assessment';

// @mui icons
import Icon from "@mui/material/Icon";
import Admin from "layouts/admin";
import MasterFranchises from "layouts/masterFranchises";
import RegularFranchises from "layouts/regularFranchises";
import Users from "layouts/users";
import Customer from "layouts/customer";
import Transaction from "layouts/transaction";
import SuperAdmin from "layouts/superAdmin";
import Messages from "layouts/messages";
import Report from "layouts/report";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon/>,
    route: "/dashboard",
    component: <Dashboard />,
    permission: ["User", "Admin", "SuperAdmin", "Regular Franchise", "Master Franchise", "Master Super Admin", "CheckerUser"],
  },
  {
    type: "collapse",
    name: "Super Admin",
    key: "super-admin",
    icon: <SupervisorAccountIcon/>,
    route: "/super-admin",
    component: <SuperAdmin />,
    // permission: ["MasterSuperAdmin"],
    permission: ["Master Super Admin"],
  },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    icon: <AdminPanelSettingsIcon/>,
    route: "/admin",
    component: <Admin />,
    permission: ["SuperAdmin", "Master Super Admin"],
  },
  {
    type: "collapse",
    name: "Master Franchises",
    key: "master-franchises",
    icon: <BusinessCenterIcon/>,
    route: "/master-franchises",
    component: <MasterFranchises />,
    permission: ["Admin", "SuperAdmin", "Master Super Admin"],
  },
  {
    type: "collapse",
    name: "Regular Franchises",
    key: "regular-franchises",
    icon: <CoPresentIcon/>,
    route: "/regular-franchises",
    component: <RegularFranchises />,
    permission: ["Admin", "SuperAdmin", "Master Franchise", "Master Super Admin"],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <PersonAddAltIcon/>,
    route: "/users",
    component: <Users />,
    permission: ["Admin", "Master Super Admin"],
  },
  {
    type: "collapse",
    name: "Customer",
    key: "customer",
    icon: <PersonIcon/>,
    route: "/customer",
    component: <Customer />,
    permission: ["User", "Admin", "Regular Franchise", "Master Franchise", "Master Super Admin", "CheckerUser"],
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transaction",
    icon: <ReceiptIcon/>,
    route: "/transaction",
    component: <Transaction />,
    permission: ["Admin", "SuperAdmin", "Regular Franchise", "Master Franchise", "Master Super Admin"],
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    icon: <ForumIcon/>,
    route: "/messages",
    component: <Messages />,
    permission: ["User", "Admin", "SuperAdmin", "Regular Franchise", "Master Franchise", "Master Super Admin"],
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    icon: <AssessmentIcon/>,
    route: "/report",
    component: <Report />,
    permission: ["User", "Admin", "CheckerUser"],
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  //   // permission: ["User", "Admin", "SuperAdmin", "Regular Franchise", "Master Franchise"],
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up/:type",
  //   component: <SignUp />,
  //   permission: ["User", "Admin", "SuperAdmin", "Regular Franchise", "Master Franchise"],
  // },
];

export default routes;
