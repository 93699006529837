/* eslint-disable */
import { customerRoutes } from 'constant/apiRoutes';
import apiClient from 'utilities/configureAxios';
class CustomerAPI {
  constructor() {
    this.client = apiClient;
  }

  CreateCustomer = async (payload = {}) => {
    try {
      const res = await this.client.post(customerRoutes.CREATE_CUSTOMER, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  GetAllCustomer = async (payload = {}) => {
    try {
      const res = await this.client.get(`${customerRoutes.GET_ALL_CUSTOMER}?search=${payload?.search}&page=${payload?.page}&pageSize=${payload?.pageSize}&type=${payload?.type}&endDate=${payload?.endDate}&startDate=${payload?.startDate}`);
      return res;
    } catch (error) {
      return error;
    }
  };
  AttachedReport = async (payload = {}) => {
    try {
      const res = await this.client.post(customerRoutes.CUSTOMER_ATTACHED_REPORT, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  RejectCustomer = async (payload = {}) => {
    try {
      const res = await this.client.post(customerRoutes.REJECT_CUSTOMER, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  ReUploadFile = async (payload = {}) => {
    try {
      const res = await this.client.post(customerRoutes.RE_UPLOAD_FILE, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  DownloadZip = async (id = "") => {
    try {
      const res = await this.client.get(`${customerRoutes.DOWNLOAD_ZIP}/${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };
  ReDownloadZip = async (id = "") => {
    try {
      const res = await this.client.get(`${customerRoutes.RE_DOWNLOAD_ZIP}/${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };
  VerifyCustomer = async (id = "") => {
    try {
      const res = await this.client.get(`${customerRoutes.VERIFY_CUSTOMER}/${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default CustomerAPI;
