import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          display: "flex",
          gap: "10px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          <Link style={{ color: "#fff", fontSize: "13px" }} to="/policy">
            Privacy Policy
          </Link>
          <Link style={{ color: "#fff", fontSize: "13px" }} to="/terms">
            Terms And Condition
          </Link>
          <Link style={{ color: "#fff", fontSize: "13px" }} to="/refund">
            Cancellation And Refund Policy
          </Link>
          <Link style={{ color: "#fff", fontSize: "13px" }} to="/shipping">
            Shipping And Delivery Policy
          </Link>
          <Link style={{ color: "#fff", fontSize: "13px" }} to="/contact_us">
            Contact Us
          </Link>
        </div>
        <div>
          <Link style={{ color: "#fff", fontSize: "13px" }} to="/contact_us">
            © Copyright MindTech. All Rights Reserved
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
