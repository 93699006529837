/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import MasterSuperAdmin from "./UserDashBoards/MasterSuperAdmin/index.js";
import SuperAdmin from "./UserDashBoards/SuperAdmin/index.js";
import Admin from "./UserDashBoards/Admin/index.js";
import MasterFranchises from "./UserDashBoards/MasterFranchises/index.js";
import RegularFranchises from "./UserDashBoards/RegularFranchises/index.js";
import User from "./UserDashBoards/User/index.js";
import { Helmet } from "react-helmet";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const authUser = JSON.parse(localStorage.getItem("user"));
  const roleName = authUser?.role?.name;

  const TITLE = "Dmit Report | Dashboard";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <DashboardLayout>
        {roleName == "Master Super Admin" && <MasterSuperAdmin />}
        {roleName == "SuperAdmin" && <SuperAdmin />}
        {roleName == "Admin" && <Admin />}
        {roleName == "Master Franchise" && <MasterFranchises />}
        {roleName == "Regular Franchise" && <RegularFranchises />}
        {roleName == "User" && <User />}
        {roleName == "CheckerUser" && <User />}
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
