/* eslint-disable */
import { messagesRoutes } from "constant/apiRoutes";
import apiClient from "utilities/configureAxios";
class MessagesAPI {
  constructor() {
    this.client = apiClient;
  }

  GetMessages = async (payload = {}) => {
      try {
        const res = await this.client.get(`${messagesRoutes.GET_MESSAGES}?page=${payload?.page}&pageSize=${payload?.pageSize}`);
        return res;
      } catch (error) {
        return error;
      }
  };
  SendMessages = async (payload = {}) => {
      try {
        const res = await this.client.post(messagesRoutes.SEND_MESSAGE, payload);
        return res;
      } catch (error) {
        return error;
      }
  };
  DeleteMessages = async (payload = {}) => {
      try {
        const res = await this.client.post(messagesRoutes.DELETE_MESSAGE, payload);
        return res;
      } catch (error) {
        return error;
      }
  };
}

export default MessagesAPI;
