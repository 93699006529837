/* eslint-disable prettier/prettier */
export const authRoutes = {
  LOGIN: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/auth/login`,
  REFRESH_TOKEN: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/auth/refresh_token`,
  CHANGE_PASSWORD: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/password`,
};
export const usersRoutes = {
  GET_ALL_USERS: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/users/list`,
  CREATE_USER: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/create`,
  UPDATE_USER: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user`,
  NOTIFICATION_COUNT: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/notificationsCount`,
  NOTIFICATION_LISTS: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/notifications/bell`,
  STATUS_CHANGE: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/activeorinactive`,
  PAYMENT_INTEGRATION_CHANGE: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/activeorinactivePayment`,
};
export const rolesRoutes = {
  GET_ALL_ROLES: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/roles`,
};
export const balanceRoutes = {
  ADD_BALANCE: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/addBalance`,
  GET_USER_BALANCE: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/balance`,
  GET_BALANCE_HISTORY: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/balanceHistory`,
};
export const customerRoutes = {
  CREATE_CUSTOMER: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/create`,
  GET_ALL_CUSTOMER: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/lists`,
  CUSTOMER_ATTACHED_REPORT: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/attechedReport`,
  REJECT_CUSTOMER: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/approveRejectReport`,
  RE_UPLOAD_FILE: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/reUploadCustomerFingerprint`,
  DOWNLOAD_ZIP: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/downloadedReport`,
  RE_DOWNLOAD_ZIP: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/reDownloadedReport`,
  VERIFY_CUSTOMER: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/verifybyreport`,
};
export const messagesRoutes = {
  GET_MESSAGES: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/messages`,
  SEND_MESSAGE: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/sentMessage`,
  DELETE_MESSAGE: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/deleteMessage`,
};
export const dashBoardRoutes = {
  GET_COUNT: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/dashboard`,
  GRAPH_COUNT: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/dashboard/graph`,
};
export const reportRoutes = {
  CUSTOMER_REPORTS: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/customer/reports`,
};

export const plansRoutes = {
  GET_ALL_PLANS: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/plans-lists`,
  CREATE_PLANS: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/create-plan`,
  UPDATE_PLANS: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/plan`,
};

export const pricingRoutes = {
  CREATE_PRICING: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/payments/createPayment`,
  PAYMENT_STATUS: `${process.env.REACT_APP_LOCAL_API_BASE_URL}/user/payments/status`,
};
