/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
// import logo from "../../../assets/images/logo/unique.png";
import "./Index.scss";
// import style from "../LandingPage/index.module.scss";
// import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
// import { BsWhatsapp, BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import logo from "../../../assets/images/mindtech-logo.png";
import Footer from "components/Footer/Footer";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <BasicLayout image={bgImage}>
        <div
          style={{
            position: "absolute",
            right: "30px",
            top: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "2rem",
          }}
        >
          <Link style={{ color: "#fff", fontSize: "16px" }} to="/signin/amin">
            Sign In
          </Link>
          <Link style={{ color: "#fff", fontSize: "16px" }} to="/pricing">
            Pricing
          </Link>
          <Link style={{ color: "#fff", fontSize: "16px" }} to="/contact_us">
            Contact Us
          </Link>
        </div>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Contact us
            </MDTypography>
          </MDBox>
          <MDBox pb={3} px={3}>
            <div id="wrapper">
              <div id="contentspeader"></div>
              <p className="content-text">
                <div style={{ textAlign: "center" }}>
                  <img src={logo} alt="" style={{ width: "auto", height: "135px" }} />
                </div>
                <div
                  className="elementor-element elementor-element-19f9f688 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                  data-id="19f9f688"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items" style={{ listStyle: "none" }}>
                      <li className="elementor-icon-list-item" style={{ marginBottom: "10px" }}>
                        <span className="elementor-icon-list-icon" style={{ marginRight: "10px" }}>
                          <CallIcon style={{ width: "1.5rem", height: "1.5rem" }} />{" "}
                        </span>
                        <span className="elementor-icon-list-text">+91 84609 97779</span>
                      </li>
                      <li className="elementor-icon-list-item" style={{ marginBottom: "10px" }}>
                        <span className="elementor-icon-list-icon" style={{ marginRight: "10px" }}>
                          <CallIcon style={{ width: "1.5rem", height: "1.5rem" }} />{" "}
                        </span>
                        <span className="elementor-icon-list-text">+91 84601 66996</span>
                      </li>
                      <li className="elementor-icon-list-item" style={{ marginBottom: "10px" }}>
                        <span className="elementor-icon-list-icon" style={{ marginRight: "10px" }}>
                          <EmailIcon style={{ width: "1.5rem", height: "1.5rem" }} />{" "}
                        </span>
                        <span className="elementor-icon-list-text">info@mindtechindia.com</span>
                      </li>
                      <li
                        className="elementor-icon-list-item"
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <span className="elementor-icon-list-icon">
                          <LocationOnIcon style={{ width: "2rem", height: "2rem" }} />
                        </span>
                        <span className="elementor-icon-list-text">
                          A- 410, Star World, Green City Road, GauravPath, Pal , Surat Gujarat,
                          India.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </p>
            </div>
          </MDBox>
        </Card>
      </BasicLayout>
      <Footer />
      {/* <div id='policycontainer'>
            <div id='header'>
            </div>
            <div id="wrapper">
                <p id='contentheader'>Contact us</p>
                <div id="contentspeader"></div>
                <p id="updated-date">Last updated on Oct 12th 2023</p>
                <p className='content-text'>You may contact us using the information below:
                </p>
                <p className='content-text'>
                Merchant Legal entity name: Unique World Placement
                <br />
                  Registered Address: T-1 3rd Floor, Time Square building, Beside bhulkabhavan School, Adajan Surat GUJARAT 395009
                <br />
                  Telephone No: 9974877260
                <br />
                  E-Mail ID: helpuniqueworld@gmail.com
                </p>
              </div>

        </div> */}
    </>
  );
};

export default ContactUs;
