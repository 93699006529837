/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
// import logo from "../../../assets/images/logo/unique.png";
import "./Index.scss";
// import style from "../LandingPage/index.module.scss";
// import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
// import { BsWhatsapp, BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/mindtech-logo.png";

const ShippingAndDelivery = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div id="policycontainer">
      <div id="header">
        <img src={logo} alt="" />
      </div>
      <div id="wrapper">
        <p id="contentheader">Shipping & Delivery Policy</p>
        <div id="contentspeader"></div>
        <p id="updated-date">Last updated on July 1st 2024</p>
        <p className="content-text">Shipping is not applicable for business.</p>
      </div>
    </div>
  );
};

export default ShippingAndDelivery;
