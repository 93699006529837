/* eslint-disable */
import { authRoutes } from 'constant/apiRoutes';
import apiClient from 'utilities/configureAxios';
class AuthAPI {
  constructor() {
    this.client = apiClient;
  }

  SignIn = async (payload = {}) => {
    try {
      const res = await this.client.post(authRoutes.LOGIN, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  ChangePassword = async (payload = {}) => {
    try {
      const res = await this.client.put(`${authRoutes.CHANGE_PASSWORD}/${payload?.userId}`, payload?.data);
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default AuthAPI;
