/* eslint-disable */
import { pricingRoutes } from "../../constant/apiRoutes";
import apiClient from "utilities/configureAxios";
class PricingAPI {
  constructor() {
    this.client = apiClient;
  }

  CreatePricing = async (payload = {}) => {
    try {
      const res = await this.client.post(pricingRoutes.CREATE_PRICING, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  PaymentStatus = async (payload = {}) => {
    try {
      const res = await this.client.get(`${pricingRoutes.PAYMENT_STATUS}/${payload?.id}`);
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default PricingAPI;
