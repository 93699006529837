/* eslint-disable prettier/prettier */

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import AuthAPI from "api/auth";
import { setUserBalance, useMaterialUIController } from "context";
import BalanceAPI from "api/balance";
import MDSnackbar from "components/MDSnackbar";
import { IconButton, Input, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import logo from "../../../assets/images/mindtech-logo.png";
import Footer from "components/Footer/Footer";

function Basic() {
  const params = useParams();
  const [type, setType] = useState("");
  useEffect(() => {
    if (params?.type == "amin") {
      setType("masterSuperAdmin");
    } else if (params?.type == "sadmin") {
      setType("superAdmin");
    } else if (params?.type == "admin") {
      setType("admin");
    } else if (params?.type == "mf") {
      setType("master");
    } else if (params?.type == "rf") {
      setType("regular");
    } else if (params?.type == "user") {
      setType("user");
    } else if (params?.type == "checker") {
      setType("checker");
    }
  }, [params]);

  console.info("type => ", type);
  const [controller, dispatch] = useMaterialUIController();
  const service = new AuthAPI();
  const balanceService = new BalanceAPI();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const getUserBalance = async () => {
    const resp = await balanceService.GetUserBalance();
    if (resp?.data?.info?.isSuccess == true) {
      setUserBalance(dispatch, resp?.data?.data?.balance);
    }
  };

  const handleSignIn = async () => {
    const response = await service.SignIn({
      username: userName,
      password: password,
      type,
    });
    if (response?.data?.data && response?.data?.info?.isSuccess == true) {
      localStorage.setItem("user", JSON.stringify(response?.data?.data?.user));
      localStorage.setItem("token", response?.data?.data?.token);
      localStorage.setItem("refresh_token", response?.data?.data?.refresh_token);
      localStorage.setItem(
        "type",
        response?.data?.data?.user?.role?.name == "Master Super Admin"
          ? "amin"
          : response?.data?.data?.user?.role?.name == "SuperAdmin"
          ? "sadmin"
          : response?.data?.data?.user?.role?.name == "Master Franchise"
          ? "mf"
          : response?.data?.data?.user?.role?.name == "Regular Franchise"
          ? "rf"
          : response?.data?.data?.user?.role?.name == "Admin"
          ? "admin"
          : response?.data?.data?.user?.role?.name == "CheckerUser"
          ? "checker"
          : response?.data?.data?.user?.role?.name == "User"
          ? "user"
          : "amin"
      );
      navigate("/dashboard");
      await getUserBalance();
    } else {
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          response?.message == "Network Error"
            ? response?.message
            : response?.response?.data?.info?.message
            ? response?.response?.data?.info?.message
            : response?.response?.data?.errors[0]?.msg,
      });
    }
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);

  const TITLE = "Dmit Report | Sign-in";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="SIGN IN"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <BasicLayout image={bgImage}>
        {/* <MDButton variant="gradient" style={{ position: "absolute", right: "10px", top: "10px" }} color="info" onClick={() => navigate("/pricing")}>
          pricing
        </MDButton> */}
        {params?.type == "amin" ? (
          <div
            style={{
              position: "absolute",
              right: "30px",
              top: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "2rem",
            }}
          >
            <Link style={{ color: "#fff", fontSize: "16px" }} to="/pricing">
              Pricing
            </Link>
            <Link style={{ color: "#fff", fontSize: "16px" }} to="/contact_us">
              Contact Us
            </Link>
          </div>
        ) : null}
        <Card style={{ zIndex: 9999999 }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox pb={3} px={3}>
            <MDBox component="form" role="form" textAlign="center">
              {params?.type == "amin" ? (
                <img src={logo} alt="" style={{ width: "auto", height: "135px" }} />
              ) : null}
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="UserName"
                  value={userName}
                  fullWidth
                  onChange={(e) => setUserName(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2} sx={{ display: "flex", position: "relative" }}>
                <MDInput
                  type={isShowPassword ? "text" : "password"}
                  value={password}
                  label="Password"
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                />
                <IconButton
                  size="small"
                  sx={{ position: "absolute", right: "8px", top: "8px" }}
                  aria-label="toggle password visibility"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {isShowPassword ? (
                    <VisibilityOff fontSize="small" />
                  ) : (
                    <Visibility fontSize="small" />
                  )}
                </IconButton>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth onClick={() => handleSignIn()}>
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
      {params?.type == "amin" ? <Footer /> : null}
    </>
  );
}

export default Basic;
