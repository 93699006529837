/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */

import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import SignIn from "layouts/authentication/sign-in";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import PrivateRoute from "utilities/PrivateRoutes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "./assets/styles/global.css";
import Pricing from "layouts/pricing";
import PaymentOrder from "layouts/payment-order";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Policy from "layouts/authentication/FooterPolicy/Policy";
import TermsAndCondition from "layouts/authentication/FooterPolicy/TermsAndCondition";
import CancellationAndRefund from "layouts/authentication/FooterPolicy/CancellationAndRefund";
import ShippingAndDelivery from "layouts/authentication/FooterPolicy/ShippingAndDelivery";
import ContactUs from "layouts/authentication/FooterPolicy/ContactUs";
import Plans from "layouts/plans";
import { Assessment } from "@mui/icons-material";
import PaymentStatus from "layouts/payment-order/PaymentStatus";
import { TbCoinRupeeFilled } from "react-icons/tb";
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [filterRoutes, setFilterRoutes] = useState([]);
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");

  useEffect(() => {
    const userRole = JSON.parse(localStorage.getItem("user"));
    let filterRoutes = [];
    filterRoutes = routes?.filter((item) => {
      if (item.permission?.includes(userRole?.role?.name)) {
        return item;
      }
    });

    if (userRole?.isPaymentIntegration == 1) {
      if (
        userRole?.role?.name == "Master Super Admin" ||
        userRole?.role?.name == "Regular Franchise" ||
        userRole?.role?.name == "Master Franchise" ||
        userRole?.role?.name == "Admin"
      ) {
        filterRoutes.push({
          type: "collapse",
          name: "Get Balance",
          key: "checkout",
          icon: <TbCoinRupeeFilled />,
          route: "/checkout",
          component: <PaymentOrder />,
          permission: ["Master Super Admin", "Regular Franchise", "Master Franchise", "Admin"],
        });
      }
    }
    if (userRole?.role?.name == "Master Super Admin") {
      filterRoutes.push({
        type: "collapse",
        name: "Plans",
        key: "plans",
        icon: <Assessment />,
        route: "/plans",
        component: <Plans />,
        permission: ["Master Super Admin"],
      });
    }

    setFilterRoutes(filterRoutes);
    if (token && pathname == "/") {
      navigate("/dashboard");
    } else if (token && pathname.includes("/signin")) {
      navigate(-1);
    } else if ((token == null || token == undefined || token == "") && pathname == "/") {
      navigate(`/signin/${type != null && type != undefined && type != "" ? type : "mf"}`);
    } else if (
      (token == null || token == undefined || token == "") &&
      !pathname.includes("/signin")
    ) {
      navigate(`/signin/${type != null && type != undefined && type != "" ? type : "mf"}`);
    } else if ((token == null || token == undefined || token == "") && pathname == "/signin") {
      navigate(`/signin/${type != null && type != undefined && type != "" ? type : "mf"}`);
    }
  }, [routes, token]);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {token && <DashboardNavbar />}

        {layout === "dashboard" && token && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard 2"
              routes={filterRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {/* {layout === "vr" && token && <Configurator />} */}
        <Routes>
          {/* <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<TermsAndCondition />} />
          <Route path="/refund" element={<CancellationAndRefund />} />
          <Route path="/shipping" element={<ShippingAndDelivery />} /> */}
          {/* <Route path="/contact_us" element={<ContactUs />} /> */}
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/signin/:type" element={<SignIn />} />
          <Route path="/payment/:id" element={<PaymentStatus />} />
          <Route path="/pricing" element={<Pricing />} />
          {filterRoutes?.map((route) => {
            return (
              <>
                <Route
                  key={route?.route}
                  path={route?.route}
                  element={<PrivateRoute>{route?.component}</PrivateRoute>}
                />
              </>
            );
          })}
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {token && <DashboardNavbar />}

      {layout === "dashboard" && token && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Material Dashboard 2"
            routes={filterRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && token && <Configurator />}
      <Routes>
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<TermsAndCondition />} />
        <Route path="/refund" element={<CancellationAndRefund />} />
        <Route path="/shipping" element={<ShippingAndDelivery />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/signin/:type" element={<SignIn />} />
        <Route path="/payment/:id" element={<PaymentStatus />} />
        <Route path="/pricing" element={<Pricing />} />
        {filterRoutes?.map((route) => {
          return (
            <>
              <Route
                key={route?.route}
                path={route?.route}
                element={<PrivateRoute>{route?.component}</PrivateRoute>}
              />
            </>
          );
        })}
      </Routes>
    </ThemeProvider>
  );
}
