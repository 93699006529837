/* eslint-disable prettier/prettier */
import { Backdrop, Card, CircularProgress, Grid, TablePagination } from "@mui/material";
import BalanceAPI from "api/balance";
import ReportAPI from "api/report";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "utilities/predefinedRanges";

const Report = () => {
  const reportService = new ReportAPI();
  const [customerReportList, setCustomerReportList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [filterDates, setFilterDates] = useState([new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000), new Date()]);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const columns = [
    {
      Header: "sn",
      accessor: "id",
      align: "center",
    },
    {
      Header: "customer name",
      accessor: "name",
      align: "center",
    },
    {
      Header: "created Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "client name",
      accessor: "createdUser.username",
      align: "center",
    },
    {
      Header: "download by",
      accessor: "downloadedUser.username",
      align: "center",
    },
    {
      Header: "Check by",
      accessor: "verifyUser.username",
      align: "center",
    },
    {
      Header: "Upload by",
      accessor: (rowData) => {
        return rowData?.reportedUser?.username ? rowData?.reportedUser?.username : ""
      },
      align: "center",
    },
  ];

  useEffect(() => {
    getCustomerReport();
  }, [page, rowsPerPage, filterDates]);

  const getCustomerReport = async () => {
    setIsLoading(true);
    const payload = {
      startDate: moment(filterDates[0]).format("yyyy-MM-DD"),
      endDate: moment(filterDates[1]).format("yyyy-MM-DD"),
      page: page + 1,
      pageSize: rowsPerPage,
    };
    const resp = await reportService.GetMessages(payload);
    if (resp?.data?.info?.isSuccess == true) {
      setCustomerReportList(resp?.data?.data?.customers?.results);
      setTotalUsers(resp?.data?.data?.customers?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const rows = customerReportList;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);

  const TITLE = "Dmit Report | Report";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="MESSAGES"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="table-header">
                    <MDTypography variant="h6" color="white">
                      REPORT
                    </MDTypography>
                    <DateRangePicker
                      ranges={predefinedRanges}
                      format="dd-MM-yyyy"
                      showOneCalendar
                      value={filterDates}
                      placeholder="Select Filter Date"
                      style={{ width: 300 }}
                      placement="bottomEnd"
                      onChange={(e) => setFilterDates(e)}
                      onClean={() => setFilterDates([new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000), new Date()])}
                    />
                  </div>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                <TablePagination
                  component="div"
                  count={totalUsers}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Report;
