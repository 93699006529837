/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Switch,
  FormControlLabel,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/mindtech-logo.png";
import Footer from "components/Footer/Footer";

const StyledTab = styled(Tab)`
  transition: color 0.7s ease-in;
  transition: color 0.7s ease-out;
`;

const Pricing = () => {
  const navigate = useNavigate();
  const [isYearly, setIsYearly] = useState(false);

  const togglePlan = () => {
    setIsYearly(!isYearly);
  };

  const plans = [
    {
      price: "3,000",
      features: "Get 10 Balance",
    },
    {
      price: "30,000",
      discountPrice: "21,000",
      features: "Get 100 Balance",
    },
    {
      price: "60,000",
      discountPrice: "39,000",
      features: "Get 200 Balance",
    },
  ];

  const plans1 = [
    {
      price: "5,000",
      features: "Get 10 Balance",
    },
    {
      price: "50,000",
      discountPrice: "30,000",
      features: "Get 100 Balance",
    },
  ];

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const generateEmailBody = (plan) => {
    return encodeURIComponent(
      `Dear Mind Tech Sales Team,\n\nI am interested in purchasing the plan worth INR ${plan?.discountPrice} from Mind Tech. Below are my details:\n\nName: \nEmail: \nPhone: \nCompany Name: \nAddress: \n\nPlease provide me with further information on how to proceed with the payment and activation of the plan.\n\nThank you!\n\nBest regards,\n`
    );
  };

  return (
    <>
      <MDBox
        width="100vw"
        height="100%"
        minHeight="100vh"
        // bgColor={bgImage}
        sx={{ overflowX: "hidden" }}
      >
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              bgImage &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            // background: "#cfcfcf",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div
          style={{
            position: "absolute",
            right: "30px",
            top: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "2rem",
          }}
        >
          <Link style={{ color: "#fff", fontSize: "16px" }} to="/signin/amin">
            Sign In
          </Link>
          <Link style={{ color: "#fff", fontSize: "16px" }} to="/pricing">
            Pricing
          </Link>
          <Link style={{ color: "#fff", fontSize: "16px" }} to="/contact_us">
            Contact Us
          </Link>
        </div>
        <MDBox px={1} width="100%" height="100vh" mx="auto">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Grid item xs={12} sm={12} md={12}>
              <Container
                sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "2rem" }}
              >
                <Box textAlign="center" my={4}>
                  <div
                    style={{
                      textAlign: "center",
                      background: "#fff",
                      width: "fit-content",
                      borderRadius: "4px",
                      margin: "30px auto",
                    }}
                  >
                    <img src={logo} alt="" style={{ width: "auto", height: "100px" }} />
                  </div>
                  <Typography sx={{ color: "#fff" }} variant="h4" component="h1" gutterBottom>
                    Our Pricing Plans
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Tabs value={value} onChange={handleChange} centered>
                      <StyledTab label="Domestic" sx={{ padding: "10px 20px" }} />
                      <StyledTab label="International" sx={{ padding: "10px 20px" }} />
                    </Tabs>
                  </Box>
                </Box>
                <Grid container spacing={4} sx={{ justifyContent: "center", width: "100%" }}>
                  {value == 0
                    ? plans.map((plan, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Card>
                            <MDBox
                              variant="gradient"
                              bgColor="info"
                              borderRadius="lg"
                              coloredShadow="info"
                              mx={2}
                              mt={-3}
                              p={2}
                              mb={1}
                              textAlign="center"
                            >
                              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    style={
                                      plan?.discountPrice
                                        ? {
                                            color: "#ccc",
                                            textDecoration: "line-through",
                                            marginRight: "1rem",
                                          }
                                        : {}
                                    }
                                  >
                                    &#8377;{plan?.price}
                                  </span>
                                  {plan?.discountPrice ? (
                                    <span>&#8377;{plan?.discountPrice}</span>
                                  ) : null}
                                </div>
                              </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3} px={3}>
                              <MDBox component="form" role="form">
                                <MDBox mb={2}>
                                  <MDTypography sx={{ textAlign: "center" }}>
                                    {plan?.features}
                                  </MDTypography>
                                </MDBox>
                                <MDBox mt={4} mb={1}>
                                  <MDButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    onClick={(e) => {
                                      // window.location = `mailto:${
                                      //   process.env.REACT_APP_LOCAL_PRICING_EMAIL || ""
                                      // }?body=${generateEmailBody(plan)}`;
                                      navigate("/signin/admin");
                                    }}
                                  >
                                    Buy It
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </MDBox>
                          </Card>
                        </Grid>
                      ))
                    : plans1.map((plan, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Card>
                            <MDBox
                              variant="gradient"
                              bgColor="info"
                              borderRadius="lg"
                              coloredShadow="info"
                              mx={2}
                              mt={-3}
                              p={2}
                              mb={1}
                              textAlign="center"
                            >
                              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    style={
                                      plan?.discountPrice
                                        ? {
                                            color: "#ccc",
                                            textDecoration: "line-through",
                                            marginRight: "1rem",
                                          }
                                        : {}
                                    }
                                  >
                                    &#8377;{plan?.price}
                                  </span>
                                  {plan?.discountPrice ? (
                                    <span>&#8377;{plan?.discountPrice}</span>
                                  ) : null}
                                </div>
                              </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3} px={3}>
                              <MDBox component="form" role="form">
                                <MDBox mb={2}>
                                  <MDTypography sx={{ textAlign: "center" }}>
                                    {plan?.features}
                                  </MDTypography>
                                </MDBox>
                                <MDBox mt={4} mb={1}>
                                  <MDButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    onClick={(e) => {
                                      navigate("/signin/admin");
                                      // window.location = `mailto:${
                                      //   process.env.REACT_APP_LOCAL_PRICING_EMAIL || ""
                                      // }?body=${generateEmailBody(plan)}`;
                                    }}
                                  >
                                    Buy It
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </MDBox>
                          </Card>
                        </Grid>
                      ))}
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </MDBox>
    </>
  );
};

export default Pricing;
