/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Icon,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import moment from "moment";
import React from "react";

const AddBalance = ({
  setAddBalanceRow,
  addBalanceRow,
  addBalanceNumber,
  setAddBalanceNumber,
  isLoading = false,
  handleSubmitAddBalance = () => {},
}) => {
  const style = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  return (
    <>
      <Modal
        open={addBalanceRow == null ? false : true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Backdrop
            sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <MDButton
            variant="primary"
            color="light"
            size="small"
            style={{ backgroundColor: "#fff", position: "absolute", top: "10px", right: "0" }}
            onClick={() => {
              setAddBalanceRow(null);
            }}
          >
            <Icon fontSize="small">close</Icon>
          </MDButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "1rem" }}
          >
            {"Add Balance"}
          </Typography>
          <div className="modal-content">
            <div style={{ display: "flex", gap: "1rem" }}>
              <TextField
                id="outlined-basic"
                label="User Name"
                disabled
                value={addBalanceRow?.username}
                variant="outlined"
                sx={{ width: "50%" }}
              />
              <TextField
                id="outlined-basic"
                label="Creation Date"
                disabled
                value={moment(addBalanceRow?.created_at).format("DD-MM-YYYY")}
                variant="outlined"
                sx={{ width: "50%" }}
              />
            </div>
            <div style={{ display: "flex", gap: "1rem" }}>
              <TextField
                id="outlined-basic"
                label="Parent"
                disabled
                value={addBalanceRow?.createdByUser?.username}
                variant="outlined"
                sx={{ width: "50%" }}
              />
              <TextField
                id="outlined-basic"
                label="Available Balance"
                disabled
                value={addBalanceRow?.balance}
                variant="outlined"
                sx={{ width: "50%" }}
              />
            </div>
            <div style={{ display: "flex", gap: "1rem" }}>
              <TextField
                id="outlined-basic"
                label="Add Balance"
                value={addBalanceNumber}
                variant="outlined"
                sx={{ width: "50%" }}
                onChange={(e) => setAddBalanceNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="create-new-btn-container">
            <Button
              variant="contained"
              sx={{ color: "#fff" }}
              onClick={() => handleSubmitAddBalance()}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddBalance;
