/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
import {
  Backdrop,
  Badge,
  Button,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import BalanceAPI from "api/balance";
import CustomerAPI from "api/customer";
import UsersAPI from "api/users";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setUserBalance } from "context";
import AddBalance from "examples/AddBalance";
import CreateUser from "examples/CreateUser";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import React, { useEffect, useState } from "react";
import getRoles from "utilities/getRoles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloseIcon from "@mui/icons-material/Close";
import Popover from "@mui/material/Popover";
import RejectReason from "examples/RejectReason";
import AWS from "aws-sdk";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import Swal from "sweetalert2";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "utilities/predefinedRanges";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Customer = () => {
  const [controller, dispatch] = useMaterialUIController();
  const service = new UsersAPI();
  const location = useLocation().search;
  const params = new URLSearchParams(location).get("type");
  const customerService = new CustomerAPI();
  const balanceService = new BalanceAPI();
  const authUser = JSON.parse(localStorage.getItem("user"));
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [roles, setRoles] = useState([]);
  const [name, setName] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenAlert2, setIsOpenAlert2] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [updateUploadFile, setUpdateUploadFile] = useState(null);
  const [isOpenRejectCustomerPopup, setIsOpenRejectCustomerPopup] = useState({
    isOpen: false,
    row: {},
  });
  const [rejectReason, setRejectReason] = useState("");
  const [filterDates, setFilterDates] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedCustomerFilterType, setSelectedCustomerFilterType] = useState("");

  const awsBUCKET_NAME = process.env.REACT_APP_LOCAL_AWS_BUCKET_NAME || "dmit-report";
  const awsRegion = process.env.REACT_APP_LOCAL_AWS_REGION || "ap-south-1";
  const awsAccessKey = process.env.REACT_APP_LOCAL_AWS_S3_ACCESS_KEY;
  const awsSecretKey = process.env.REACT_APP_LOCAL_AWS_S3_SECRET_KEY;

  useEffect(() => {
    if (params == "rejected") {
      setSelectedCustomerFilterType(params);
    } else if (params == "completed") {
      setSelectedCustomerFilterType(params);
    } else if (params == "pending") {
      setSelectedCustomerFilterType(params);
    } else {
      setSelectedCustomerFilterType("all");
    }
  }, [params]);

  const handleUploadCustomerFile = async (file = {}, rowData) => {
    setIsLoading(true);
    let fileLink;
    if (file != null && file != {}) {
      fileLink = await UploadS3(file);
    }
    const payload = {
      customerId: rowData?.id,
      attechFile: fileLink,
    };
    const fm = new FormData();
    for (const key in payload) {
      fm.append(key, payload[key]);
    }

    const resp = await customerService.AttachedReport(fm);

    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllCustomersList();
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleSubmitRejectReason = async () => {
    setIsLoading(true);

    const uploadedData = await isOpenRejectCustomerPopup?.row?.reports?.filter((item) => {
      if (item?.fileType == "UPLOAD" && item?.reportStatus == "ACCEPTED") {
        return item;
      }
    });

    const payload = {
      customerId: `${isOpenRejectCustomerPopup?.row?.id}`,
      reportId: uploadedData?.[0]?.id,
      approve: false,
      reason: rejectReason,
    };

    const resp = await customerService.RejectCustomer(payload);

    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllCustomersList();
      setIsOpenRejectCustomerPopup({
        isOpen: false,
        row: {},
      });
      setRejectReason("");
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const UploadS3 = async (file, path = "/uploads") => {
    return new Promise(async (resolve, reject) => {
      try {
        const s3 = new AWS.S3({
          region: awsRegion,
          accessKeyId: awsAccessKey,
          secretAccessKey: awsSecretKey,
        });
        const fileName = file.name;
        const uploadLink = await s3
          .upload({
            Bucket: awsBUCKET_NAME,
            Body: file,
            Key: fileName,
          })
          .promise();

        resolve(uploadLink?.Location ? uploadLink?.Location : null);
        // resolve(
        //   uploadLink?.Location
        //     ? {
        //         url: uploadLink?.Location,
        //         s3Key: uploadLink?.key,
        //         s3Bucket: uploadLink?.Bucket,
        //         success: true,
        //       }
        //     : { url: null, success: false }
        // );
      } catch (error) {
        console.log("-------------------");
        console.log("error S3", error);
        console.log("-------------------");
        setIsLoading(false);
      }
    });
  };

  const handleReUploadFile = async (rowData = {}, file = {}) => {
    setIsLoading(true);
    let fileLink;
    if (file != null && file != {}) {
      fileLink = await UploadS3(file);
    }
    const payload = {
      customerId: rowData?.id,
      fileName: file?.name,
      attechFile: file != null && file != {} && fileLink,
    };
    const fm = new FormData();
    for (const key in payload) {
      fm.append(key, payload[key]);
    }
    const resp = await customerService.ReUploadFile(fm);

    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllCustomersList();
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleDownloadZip = async (rowData = {}, item = {}) => {
    setIsLoading(true);
    if (rowData?.downloadedBy == null) {
      const resp = await customerService.DownloadZip(rowData?.id || "");
      if (resp?.data?.info?.isSuccess == true) {
        setIsLoading(false);
        getAllCustomersList();
        window.open(item?.reportLink);
      } else {
        setIsLoading(false);
        setIsOpenAlert(true);
        setErrorMsg({
          type: "error",
          content:
            resp?.message == "Network Error"
              ? resp?.message
              : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
        });
      }
    } else {
      if (authUser?.role?.name == "Admin" || rowData?.downloadedUser?.id == authUser?.id) {
        window.open(item?.reportLink);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsOpenAlert(true);
        setErrorMsg({
          type: "error",
          content: "File already downloaded by another user",
        });
      }
    }
  };

  const handleSubmitVerifyCustomer = async (rowData = {}) => {
    setIsLoading(true);
    const resp = await customerService.VerifyCustomer(rowData?.id || "");
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllCustomersList();
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleReDownloadZip = async (rowData = {}, item) => {
    setIsLoading(true);
    const resp = await customerService.ReDownloadZip(rowData?.id || "");
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllCustomersList();
      window.open(item?.reportLink);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const adminColumns = [
    {
      Header:
        authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? "action" : "rejected",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        const rejectedData = rowData?.reports?.filter((item) => {
          if (item?.reportStatus == "REJECTED") {
            return item;
          }
        });
        const completedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "DOWNLOAD") {
            return item;
          }
        });

        return (
          <>
            <div style={{ display: "flex", gap: "1rem" }}>
              {authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                rejectedData?.length == 0 && completedData?.length == 0 ? (
                  <>
                    <MDButton
                      color="error"
                      variant="contained"
                      component="label"
                      disabled={rowData?.downloadedBy != null ? false : true}
                      onClick={() => {
                        if (rowData?.downloadedBy != null) {
                          if (authUser?.role?.name != "Master Super Admin") {
                            setIsOpenRejectCustomerPopup({
                              isOpen: true,
                              row: rowData,
                            });
                          }
                        }
                      }}
                    >
                      Reject
                    </MDButton>
                  </>
                ) : null
              ) : uploadedData?.length ? (
                uploadedData?.map((item) => {
                  if (
                    item?.reportStatus == "REJECTED" &&
                    rowData?.isReUpload == 1 &&
                    rowData?.createdBy == authUser?.id
                  ) {
                    const diffInMillis = new Date() - new Date(rowData?.rejectedAt);
                    const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
                    const differenceDays =
                      Number(rowData?.createdUser?.rejectedDaysCount) - Number(diffInDays);
                    return differenceDays >= 0 ? (
                      <Tooltip title={`${differenceDays} Days Remaining`}>
                        <MDButton color="error" variant="contained" component="label">
                          Re-Upload
                          {authUser?.role?.name != "Master Super Admin" ? (
                            <input
                              type="file"
                              hidden
                              accept="*"
                              onChange={(e) => {
                                if (authUser?.role?.name != "Master Super Admin") {
                                  const file = e.target.files[0];
                                  const allowedExtensions = ["zip", "rar"];
                                  const fileExtension = file.name.split(".").pop().toLowerCase();

                                  if (allowedExtensions.includes(fileExtension)) {
                                    const file = e.target.files[0];
                                    let uploadedFileName = file?.name?.trim();
                                    if (uploadedFileName?.endsWith(".zip")) {
                                      uploadedFileName = uploadedFileName?.slice(0, -4);
                                    } else if (uploadedFileName?.endsWith(".rar")) {
                                      uploadedFileName = uploadedFileName?.slice(0, -4);
                                    }
                                    if (rowData?.name === uploadedFileName) {
                                      return handleReUploadFile(rowData, file);
                                    } else {
                                      return setIsOpenAlert2(true);
                                    }
                                  } else {
                                    alert("Invalid file type. Please select a .zip or .rar file.");
                                    e.target.value = "";
                                  }
                                }
                              }}
                            />
                          ) : null}
                        </MDButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={`This customer was rejected on the ${moment(
                          rowData?.rejectedAt
                        ).format("DD-MM-YYYY")}, and file had to be uploaded in ${Number(
                          rowData?.createdUser?.rejectedDaysCount
                        )} day, Now Create new one.`}
                      >
                        <MDButton
                          // disabled
                          color="secondary"
                          variant="contained"
                          component="label"
                        >
                          Re-Upload
                        </MDButton>
                      </Tooltip>
                    );
                  }
                })
              ) : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header:
        authUser?.role?.name == "Admin" || authUser?.role?.name == "SuperAdmin" ? "Verify" : "  ",
      accessor: (rowData) => {
        return authUser?.role?.name == "Admin" || authUser?.role?.name == "SuperAdmin" ? (
          rowData?.verifyBy != null ? (
            <VerifiedIcon fontSize="medium" color="success" />
          ) : (
            ""
          )
        ) : (
          ""
        );
      },
      align: "center",
    },
    {
      Header: "sn",
      accessor: "id",
      align: "center",
    },
    {
      Header: "customer name",
      accessor: "name",
      align: "center",
    },
    {
      Header: "reference name",
      accessor: (rowData) => {
        return rowData?.createdUser?.username;
      },
      align: "center",
    },
    {
      Header: "Finger Print",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    if (
                      item?.reportStatus == "REJECTED" &&
                      (uploadedData?.length == 2 || uploadedData?.length == 1)
                    ) {
                      return (
                        <Badge badgeContent={<CloseIcon fontSize="small" />} color="primary">
                          {authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                            <MDButton
                              color="info"
                              variant="contained"
                              component="label"
                              onClick={() => handleDownloadZip(rowData, item)}
                            >
                              Download Finger Print
                            </MDButton>
                          ) : (
                            <FolderZipIcon fontSize="large" />
                          )}
                        </Badge>
                      );
                    } else if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 1) {
                      return authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                        <MDButton
                          color="info"
                          variant="contained"
                          component="label"
                          onClick={() => handleDownloadZip(rowData, item)}
                        >
                          Download Finger Print
                        </MDButton>
                      ) : (
                        <FolderZipIcon fontSize="large" />
                      );
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Created Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "re-uploded Finger Print",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    // if (uploadedData?.length > 1) {
                    if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 2) {
                      return authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                        <MDButton
                          color="info"
                          variant="contained"
                          component="label"
                          onClick={() => {
                            if (
                              authUser?.role?.name == "Admin" ||
                              rowData?.downloadedUser?.id == authUser?.id
                            ) {
                              window.open(item?.reportLink);
                            }
                          }}
                        >
                          Download Finger Print
                        </MDButton>
                      ) : (
                        <FolderZipIcon fontSize="large" />
                      );
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "re-upload Created Date",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    // if (uploadedData?.length > 1) {
                    if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 2) {
                      return moment(item?.created_at).format("DD-MM-YYYY");
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Download report",
      accessor: (rowData) => {
        const reportedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "DOWNLOAD") {
            return item;
          }
        });
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });

        reportedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        return rowData?.reportedUser != null &&
          rowData?.reportedUser != undefined &&
          rowData?.reportedUser != "" ? (
          <>
            <div>
              <MDButton
                color="info"
                variant="contained"
                component="label"
                onClick={() =>
                  reportedData[0]?.reportLink != null && window.open(reportedData[0]?.reportLink)
                }
              >
                Download File
              </MDButton>
            </div>
          </>
        ) : (
          <>
            {authUser?.role?.name == "Admin" || authUser?.role?.name == "User"
              ? uploadedData?.length
                ? uploadedData?.map((item) => {
                    if (item?.reportStatus == "REJECTED" && rowData?.isReUpload == 1) {
                      return;
                    } else if (item?.reportStatus == "ACCEPTED") {
                      <MDButton color="info" variant="contained" component="label">
                        Upload File
                        <input
                          type="file"
                          hidden
                          accept=".pdf"
                          onChange={(e) => {
                            handleUploadCustomerFile(e.target.files[0], rowData);
                          }}
                        />
                      </MDButton>;
                    }
                  })
                : ""
              : ""}
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Upload Report",
      accessor: (rowData) => {
        return (
          <MDButton color="info" variant="contained" component="label">
            Upload File
            <input
              type="file"
              hidden
              accept=".pdf"
              onChange={(e) => {
                Swal.fire({
                  title: e.target.files[0]?.name || "",
                  text: `You want to upload this file?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#1A73E8",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, upload it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    // handleSubmitVerifyCustomer(rowData);
                    handleUploadCustomerFile(e.target.files[0], rowData);
                  } else {
                    e.target.value = "";
                  }
                });
              }}
            />
          </MDButton>
        );
      },
      align: "center",
    },
    {
      Header: "Reported Date",
      accessor: (rowData) => {
        return rowData?.reportedAt != null &&
          rowData?.reportedAt != undefined &&
          rowData?.reportedAt != ""
          ? moment(rowData?.reportedAt).format("DD-MM-YYYY")
          : "";
      },
      align: "center",
    },
    {
      Header:
        authUser?.role?.name == "Master Franchise" || authUser?.role?.name == "Regular Franchise"
          ? " "
          : "reported by",
      accessor: (rowData) => {
        if (
          authUser?.role?.name == "Master Franchise" ||
          authUser?.role?.name == "Regular Franchise"
        ) {
          return "";
        } else {
          return rowData?.reportedUser?.username;
        }
      },
      align: "center",
    },
  ];
  const columns = [
    {
      Header:
        authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? "action" : "rejected",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        const rejectedData = rowData?.reports?.filter((item) => {
          if (item?.reportStatus == "REJECTED") {
            return item;
          }
        });
        const completedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "DOWNLOAD") {
            return item;
          }
        });

        return (
          <>
            <div style={{ display: "flex", gap: "1rem" }}>
              {authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                rejectedData?.length == 0 && completedData?.length == 0 ? (
                  <>
                    <MDButton
                      color="error"
                      variant="contained"
                      component="label"
                      disabled={rowData?.downloadedBy != null ? false : true}
                      onClick={() => {
                        if (rowData?.downloadedBy != null) {
                          if (authUser?.role?.name != "Master Super Admin") {
                            setIsOpenRejectCustomerPopup({
                              isOpen: true,
                              row: rowData,
                            });
                          }
                        }
                      }}
                    >
                      Reject
                    </MDButton>
                  </>
                ) : null
              ) : uploadedData?.length == 1 ? (
                uploadedData?.map((item) => {
                  if (
                    item?.reportStatus == "REJECTED" &&
                    rowData?.isReUpload == 1 &&
                    rowData?.createdBy == authUser?.id
                  ) {
                    const diffInMillis = new Date() - new Date(rowData?.rejectedAt);
                    const diffInDays = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
                    const differenceDays =
                      Number(rowData?.createdUser?.rejectedDaysCount) - Number(diffInDays);
                    return differenceDays >= 0 ? (
                      <Tooltip title={`${differenceDays} Days Remaining`}>
                        <MDButton color="error" variant="contained" component="label">
                          Re-Upload
                          {authUser?.role?.name != "Master Super Admin" ? (
                            <input
                              type="file"
                              hidden
                              accept="*"
                              onChange={(e) => {
                                if (authUser?.role?.name != "Master Super Admin") {
                                  const file = e.target.files[0];
                                  const allowedExtensions = ["zip", "rar"];
                                  const fileExtension = file.name.split(".").pop().toLowerCase();

                                  if (allowedExtensions.includes(fileExtension)) {
                                    let uploadedFileName = file?.name?.trim();
                                    if (uploadedFileName?.endsWith(".zip")) {
                                      uploadedFileName = uploadedFileName?.slice(0, -4);
                                    } else if (uploadedFileName?.endsWith(".rar")) {
                                      uploadedFileName = uploadedFileName?.slice(0, -4);
                                    }
                                    if (rowData?.name === uploadedFileName) {
                                      return Swal.fire({
                                        title: file?.name || "",
                                        text: "You want to re-upload this file?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#1A73E8",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, Re-upload it!",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          // handleSubmitVerifyCustomer(rowData);
                                          handleReUploadFile(rowData, file);
                                        } else {
                                          e.target.value = "";
                                        }
                                      });
                                    } else {
                                      return setIsOpenAlert2(true);
                                    }
                                  } else {
                                    alert("Invalid file type. Please select a .zip or .rar file.");
                                    e.target.value = "";
                                  }
                                }
                              }}
                            />
                          ) : null}
                        </MDButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={`This customer was rejected on the ${moment(
                          rowData?.rejectedAt
                        ).format("DD-MM-YYYY")}, and file had to be uploaded in ${Number(
                          rowData?.createdUser?.rejectedDaysCount
                        )} day, Now Create new one.`}
                      >
                        <MDButton
                          // disabled
                          color="secondary"
                          variant="contained"
                          component="label"
                        >
                          Re-Upload
                        </MDButton>
                      </Tooltip>
                    );
                  }
                })
              ) : rejectedData?.length == 2 ? (
                <MDButton
                  color="error"
                  variant="contained"
                  component="label"
                  onClick={() => {
                    Swal.fire({
                      title: "You simply re-upload the report once.",
                      text: "Now create a new customer to upload the report again.",
                      icon: "warning",
                      confirmButtonColor: "#1A73E8",
                    });
                  }}
                >
                  Rejected
                </MDButton>
              ) : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header:
        authUser?.role?.name == "Admin" || authUser?.role?.name == "SuperAdmin" ? "Verify" : "  ",
      accessor: (rowData) => {
        return authUser?.role?.name == "Admin" || authUser?.role?.name == "SuperAdmin" ? (
          rowData?.verifyBy != null ? (
            <VerifiedIcon fontSize="medium" color="success" />
          ) : (
            ""
          )
        ) : (
          ""
        );
      },
      align: "center",
    },
    {
      Header: "sn",
      accessor: "id",
      align: "center",
    },
    {
      Header: "customer name",
      accessor: "name",
      align: "center",
    },
    {
      Header: "reference name",
      accessor: (rowData) => {
        return rowData?.createdUser?.username;
      },
      align: "center",
    },
    {
      Header: "Finger Print",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    if (
                      item?.reportStatus == "REJECTED" &&
                      (uploadedData?.length == 2 || uploadedData?.length == 1)
                    ) {
                      return (
                        <Badge badgeContent={<CloseIcon fontSize="small" />} color="primary">
                          {authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                            <MDButton
                              color="info"
                              variant="contained"
                              component="label"
                              onClick={() => handleDownloadZip(rowData, item)}
                            >
                              Download Finger Print
                            </MDButton>
                          ) : (
                            <FolderZipIcon fontSize="large" />
                          )}
                        </Badge>
                      );
                    } else if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 1) {
                      return authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                        <MDButton
                          color="info"
                          variant="contained"
                          component="label"
                          onClick={() => handleDownloadZip(rowData, item)}
                        >
                          Download Finger Print
                        </MDButton>
                      ) : (
                        <FolderZipIcon fontSize="large" />
                      );
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Created Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "re-uploded Finger Print",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    // if (uploadedData?.length > 1) {
                    if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 2) {
                      return authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                        <MDButton
                          color="info"
                          variant="contained"
                          component="label"
                          onClick={() => {
                            if (
                              authUser?.role?.name == "Admin" ||
                              rowData?.downloadedUser?.id == authUser?.id
                            ) {
                              window.open(item?.reportLink);
                            }
                          }}
                        >
                          Download Finger Print
                        </MDButton>
                      ) : (
                        <FolderZipIcon fontSize="large" />
                      );
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "re-upload Created Date",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    // if (uploadedData?.length > 1) {
                    if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 2) {
                      return moment(item?.created_at).format("DD-MM-YYYY");
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Upload report",
      accessor: (rowData) => {
        const reportedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "DOWNLOAD") {
            return item;
          }
        });
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });

        return rowData?.reportedUser != null &&
          rowData?.reportedUser != undefined &&
          rowData?.reportedUser != "" ? (
          <>
            <div>
              {reportedData?.length
                ? reportedData?.map((item) => {
                    if (item?.reportLink != null) {
                      return (
                        <MDButton
                          color="info"
                          variant="contained"
                          component="label"
                          onClick={() => window.open(item?.reportLink)}
                        >
                          Download File
                        </MDButton>
                      );
                    }
                  })
                : null}
            </div>
          </>
        ) : (
          <>
            {authUser?.role?.name == "Admin" || authUser?.role?.name == "User"
              ? uploadedData?.length
                ? uploadedData?.map((item) => {
                    if (item?.reportStatus == "REJECTED" && rowData?.isReUpload == 1) {
                      return;
                    } else if (item?.reportStatus == "ACCEPTED") {
                      <MDButton color="info" variant="contained" component="label">
                        Upload File
                        <input
                          type="file"
                          hidden
                          accept=".pdf"
                          onChange={(e) => {
                            handleUploadCustomerFile(e.target.files[0], rowData);
                          }}
                        />
                      </MDButton>;
                    }
                  })
                : ""
              : ""}
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Reported Date",
      accessor: (rowData) => {
        return rowData?.reportedAt != null &&
          rowData?.reportedAt != undefined &&
          rowData?.reportedAt != ""
          ? moment(rowData?.reportedAt).format("DD-MM-YYYY")
          : "";
      },
      align: "center",
    },
    {
      Header:
        authUser?.role?.name == "Master Franchise" || authUser?.role?.name == "Regular Franchise"
          ? " "
          : "reported by",
      accessor: (rowData) => {
        if (
          authUser?.role?.name == "Master Franchise" ||
          authUser?.role?.name == "Regular Franchise"
        ) {
          return "";
        } else {
          return rowData?.reportedUser?.username;
        }
      },
      align: "center",
    },
  ];
  const userColumns = [
    {
      Header:
        authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? "action" : "rejected",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        const rejectedData = rowData?.reports?.filter((item) => {
          if (item?.reportStatus == "REJECTED") {
            return item;
          }
        });
        const completedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "DOWNLOAD") {
            return item;
          }
        });

        return (
          <>
            <div style={{ display: "flex", gap: "1rem" }}>
              {authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                // rejectedData?.length == 0 &&
                // completedData?.length == 0 &&
                rowData?.isReUpload == 0 && rowData?.verifyBy == null ? (
                  <>
                    <MDButton
                      color="error"
                      variant="contained"
                      component="label"
                      disabled={rowData?.downloadedBy != null ? false : true}
                      onClick={() => {
                        if (rowData?.downloadedBy != null) {
                          if (authUser?.role?.name != "Master Super Admin") {
                            setIsOpenRejectCustomerPopup({
                              isOpen: true,
                              row: rowData,
                            });
                          }
                        }
                      }}
                    >
                      Reject
                    </MDButton>
                  </>
                ) : null
              ) : uploadedData?.length ? (
                uploadedData?.map((item) => {
                  if (
                    item?.reportStatus == "REJECTED" &&
                    rowData?.isReUpload == 1 &&
                    rowData?.createdBy == authUser?.id
                  ) {
                    return (
                      <Tooltip title={`${rowData?.createdUser?.rejectedDaysCount} Days Remaining`}>
                        <MDButton color="error" variant="contained" component="label">
                          Re-Upload
                          {authUser?.role?.name != "Master Super Admin" ? (
                            <input
                              type="file"
                              hidden
                              accept="*"
                              onChange={(e) => {
                                if (authUser?.role?.name != "Master Super Admin") {
                                  const file = e.target.files[0];
                                  const allowedExtensions = ["zip", "rar"];
                                  const fileExtension = file.name.split(".").pop().toLowerCase();

                                  if (allowedExtensions.includes(fileExtension)) {
                                    handleReUploadFile(rowData, file);
                                  } else {
                                    alert("Invalid file type. Please select a .zip or .rar file.");
                                    e.target.value = "";
                                  }
                                }
                              }}
                            />
                          ) : null}
                        </MDButton>
                      </Tooltip>
                    );
                  }
                })
              ) : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Verify",
      accessor: (rowData) => {
        return rowData?.verifyBy != null ? <VerifiedIcon fontSize="medium" color="success" /> : "";
      },
      align: "center",
    },
    {
      Header: "sn",
      accessor: "id",
      align: "center",
    },
    {
      Header: "customer name",
      accessor: "name",
      align: "center",
    },
    {
      Header: "reference name",
      accessor: (rowData) => {
        return rowData?.createdUser?.username;
      },
      align: "center",
    },
    {
      Header: "Finger Print",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    if (
                      item?.reportStatus == "REJECTED" &&
                      (uploadedData?.length == 2 || uploadedData?.length == 1)
                    ) {
                      return (
                        <Badge badgeContent={<CloseIcon fontSize="small" />} color="primary">
                          {authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                            <MDButton
                              color="info"
                              variant="contained"
                              component="label"
                              onClick={() => handleDownloadZip(rowData, item)}
                            >
                              Download Finger Print
                            </MDButton>
                          ) : (
                            <FolderZipIcon fontSize="large" />
                          )}
                        </Badge>
                      );
                    } else if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 1) {
                      return authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                        <MDButton
                          color="info"
                          variant="contained"
                          component="label"
                          onClick={() => handleDownloadZip(rowData, item)}
                        >
                          Download Finger Print
                        </MDButton>
                      ) : (
                        <FolderZipIcon fontSize="large" />
                      );
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Created Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "Re-uploded Finger Print",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    // if (uploadedData?.length > 1) {
                    if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 2) {
                      return authUser?.role?.name == "Admin" || authUser?.role?.name == "User" ? (
                        <MDButton
                          color="info"
                          variant="contained"
                          component="label"
                          onClick={() => {
                            if (
                              authUser?.role?.name == "Admin" ||
                              rowData?.downloadedUser?.id == authUser?.id
                            ) {
                              if (
                                authUser?.role?.name == "Admin" ||
                                rowData?.reDownloadedBy == authUser?.id
                              ) {
                                window.open(item?.reportLink);
                              } else {
                                handleReDownloadZip(rowData, item);
                              }
                            } else {
                              setIsOpenAlert(true);
                              setErrorMsg({
                                type: "error",
                                content: "File already downloaded by another user",
                              });
                            }
                          }}
                        >
                          Download Finger Print
                        </MDButton>
                      ) : (
                        <FolderZipIcon fontSize="large" />
                      );
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "re-upload Created Date",
      accessor: (rowData) => {
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {uploadedData?.length
                ? uploadedData?.map((item) => {
                    // if (uploadedData?.length > 1) {
                    if (item?.reportStatus == "ACCEPTED" && uploadedData?.length == 2) {
                      return moment(item?.created_at).format("DD-MM-YYYY");
                    }
                  })
                : null}
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Upload report",
      accessor: (rowData) => {
        const reportedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "DOWNLOAD") {
            return item;
          }
        });
        const uploadedData = rowData?.reports?.filter((item) => {
          if (item?.fileType == "UPLOAD") {
            return item;
          }
        });

        return rowData?.reportedUser != null &&
          rowData?.reportedUser != undefined &&
          rowData?.reportedUser != "" ? (
          <>
            <div>
              {reportedData?.length
                ? reportedData?.map((item) => {
                    return (
                      <MDButton
                        color="info"
                        variant="contained"
                        component="label"
                        onClick={() => window.open(item?.reportLink)}
                      >
                        Download File
                      </MDButton>
                    );
                  })
                : null}
            </div>
          </>
        ) : (
          <>
            {authUser?.role?.name == "Admin" || authUser?.role?.name == "User"
              ? uploadedData?.length
                ? uploadedData?.map((item) => {
                    if (item?.reportStatus == "REJECTED" && rowData?.isReUpload == 1) {
                      return;
                    } else if (item?.reportStatus == "ACCEPTED") {
                      return (
                        <MDButton
                          color="info"
                          disabled={rowData?.verifyBy == null}
                          variant="contained"
                          component="label"
                        >
                          Upload File
                          <input
                            type="file"
                            hidden
                            accept=".pdf"
                            onChange={(e) => {
                              if (rowData?.verifyBy != null && rowData?.verifyBy != undefined) {
                                Swal.fire({
                                  title: e.target.files[0]?.name || "",
                                  text: "You want to sure upload this file?",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonColor: "#1A73E8",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Yes, upload it!",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    // handleSubmitVerifyCustomer(rowData);
                                    handleUploadCustomerFile(e.target.files[0], rowData);
                                  } else {
                                    e.target.value = "";
                                  }
                                });
                              }
                            }}
                          />
                        </MDButton>
                      );
                    }
                  })
                : ""
              : ""}
          </>
        );
      },
      align: "center",
    },
    {
      Header: "Reported Date",
      accessor: (rowData) => {
        return rowData?.reportedAt != null &&
          rowData?.reportedAt != undefined &&
          rowData?.reportedAt != ""
          ? moment(rowData?.reportedAt).format("DD-MM-YYYY")
          : "";
      },
      align: "center",
    },
    {
      Header: "reported by",
      accessor: (rowData) => {
        return rowData?.reportedUser?.username;
      },
      align: "center",
    },
    {
      Header: "Verify by",
      accessor: "verifyUser.username",
      align: "center",
    },
    {
      Header: "Verify at",
      accessor: (rowData) => {
        return rowData?.verifyAt != null ? moment(rowData?.verifyAt).format("DD-MM-YYYY") : "";
      },
      align: "center",
    },
  ];
  const checkerColumns = [
    {
      Header: "Reject",
      accessor: (rowData) => {
        return rowData?.rejectedBy != null && rowData?.reDownloadedBy == null ? (
          <MDButton color="error" variant="contained" component="label">
            Rejected
          </MDButton>
        ) : null;
      },
      align: "center",
    },
    {
      Header: "verify",
      accessor: (rowData) => {
        return rowData?.verifyBy == null ? (
          rowData?.downloadedBy != null ||
          rowData?.rejectedBy == null ||
          rowData?.reDownloadedBy != null ? (
            rowData?.rejectedBy != null && rowData?.reDownloadedBy == null ? null : (
              <MDButton
                color="info"
                variant="contained"
                component="label"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, verify it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleSubmitVerifyCustomer(rowData);
                    } else {
                      e.target.value = "";
                    }
                  });
                }}
              >
                Verify Now
              </MDButton>
            )
          ) : null
        ) : (
          <VerifiedIcon fontSize="medium" color="success" />
        );
      },
      align: "center",
    },
    {
      Header: "sn",
      accessor: "id",
      align: "center",
    },
    {
      Header: "customer name",
      accessor: "name",
      align: "center",
    },
    {
      Header: "Created Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "reference name",
      accessor: (rowData) => {
        return rowData?.createdUser?.username;
      },
      align: "center",
    },
    {
      Header: "Finger Print status",
      accessor: (rowData) => {
        return <span>Finger Print Downloaded</span>;
      },
      align: "center",
    },
    {
      Header: "Downloaded By",
      accessor: "downloadedUser.username",
      align: "center",
    },
    {
      Header: "Downloaded At",
      accessor: (rowData) => {
        return rowData?.downloadAt ? moment(rowData?.downloadAt).format("DD-MM-YYYY") : "";
      },
      align: "center",
    },
  ];

  const rows = getAllUsers;

  useEffect(() => {
    (async () => {
      if (selectedCustomerFilterType != "") {
        getAllCustomersList();
      }
    })();
  }, [page, rowsPerPage, selectedCustomerFilterType, filterDates]);

  useEffect(() => {
    (async () => {
      const roles = await getRoles();
      setRoles(roles);
    })();
  }, []);

  const getAllCustomersList = async () => {
    setIsLoading(true);
    const payload = {
      type: authUser?.role?.name == "Admin" ? selectedCustomerFilterType : "all",
      startDate:
        authUser?.role?.name == "Admin"
          ? filterDates != null
            ? moment(filterDates[0]).format("yyyy-MM-DD")
            : null
          : null,
      endDate:
        authUser?.role?.name == "Admin"
          ? filterDates != null
            ? moment(filterDates[1]).format("yyyy-MM-DD")
            : null
          : null,
      search: "",
      page: page + 1,
      pageSize: rowsPerPage,
    };
    const resp = await customerService.GetAllCustomer(payload);
    if (resp?.data?.data?.customers?.results && resp?.data?.info?.isSuccess == true) {
      setGetAllUsers(resp?.data?.data?.customers?.results);
      setTotalUsers(resp?.data?.data?.customers?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitUser = async () => {
    setIsLoading(true);
    let fileLink;
    if (uploadedFile != null) {
      fileLink = await UploadS3(uploadedFile);
    }
    let adminRoleId = "";
    roles?.filter((item) => {
      if (item?.name == "Regular Franchise") {
        adminRoleId = item?.id;
      }
    });
    const type = JSON.parse(localStorage.getItem("user"));
    const payload = {
      name: name,
      fileName: uploadedFile?.name,
      attechFile: uploadedFile != null && fileLink,
    };

    const fm = new FormData();

    for (const key in payload) {
      fm.append(key, payload[key]);
    }

    const resp = await customerService.CreateCustomer(fm);
    if (resp?.data?.info?.isSuccess == true) {
      getAllCustomersList();
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenCreateModal(false);
      setName("");
      setUploadedFile(null);
      getUserBalance();
      setIsLoading(false);
    } else {
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
      setIsLoading(false);
    }
  };

  const getUserBalance = async () => {
    const resp = await balanceService.GetUserBalance();
    if (resp?.data?.data?.balance && resp?.data?.info?.isSuccess == true) {
      setUserBalance(dispatch, resp?.data?.data?.balance);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);
  const TITLE = "Dmit Report | Customer";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <MDSnackbar
        color={"success"}
        icon={"close"}
        title="File name does not match the user name."
        content={"The file which you have selected does not match with the User's name"}
        open={isOpenAlert2}
        onClose={() => setIsOpenAlert2(false)}
        close={() => setIsOpenAlert2(false)}
        bgWhite
      />
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="CUSTOMER"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <CreateUser
        setIsOpenCreateModal={setIsOpenCreateModal}
        isOpenCreateModal={isOpenCreateModal}
        handleSubmitUser={handleSubmitUser}
        setUserName={setUserName}
        setName={setName}
        name={name}
        userName={userName}
        tableTitle={"CUSTOMER"}
        isCustomer={true}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        isLoading={isLoading}
      />
      <RejectReason
        isOpenRejectCustomerPopup={isOpenRejectCustomerPopup}
        setIsOpenRejectCustomerPopup={setIsOpenRejectCustomerPopup}
        tableTitle={"REASON"}
        setRejectReason={setRejectReason}
        rejectReason={rejectReason}
        handleSubmitRejectReason={handleSubmitRejectReason}
        isLoading={isLoading}
      />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="table-header">
                    <MDTypography variant="h6" color="white">
                      Customer
                    </MDTypography>
                    <div>
                      {authUser?.role?.name == "Admin" ? (
                        <>
                          <DateRangePicker
                            ranges={predefinedRanges}
                            format="dd-MM-yyyy"
                            showOneCalendar
                            value={filterDates}
                            placeholder="Select Filter Date"
                            style={{ width: 300, marginRight: "1rem" }}
                            onChange={(e) => setFilterDates(e)}
                          />
                          <FormControl sx={{ width: "180px", marginRight: "1rem" }}>
                            {/* <InputLabel id="demo-simple-select-label" sx={{ lineHeight: "1rem", color: "#000", fontWeight: "700" }}>
                            Select Role
                          </InputLabel> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedCustomerFilterType}
                              // label="Select Role"
                              sx={{ lineHeight: "2.3rem", backgroundColor: "#fff" }}
                              onChange={(e) => setSelectedCustomerFilterType(e.target.value)}
                            >
                              <MenuItem value={"all"}>All</MenuItem>
                              <MenuItem value={"completed"}>Completed</MenuItem>
                              <MenuItem value={"pending"}>Pending</MenuItem>
                              <MenuItem value={"rejected"}>Rejected</MenuItem>
                            </Select>
                          </FormControl>
                        </>
                      ) : null}
                      {authUser?.role?.name != "User" &&
                      authUser?.role?.name != "CheckerUser" &&
                      authUser?.role?.name != "Master Super Admin" &&
                      authUser?.role?.name != "SuperAdmin" ? (
                        <MDButton
                          variant="primary"
                          color="light"
                          size="small"
                          style={{ backgroundColor: "#fff" }}
                          onClick={() => setIsOpenCreateModal(true)}
                        >
                          Create
                        </MDButton>
                      ) : null}
                    </div>
                  </div>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={
                      authUser?.role?.name == "CheckerUser"
                        ? { columns: checkerColumns, rows }
                        : authUser?.role?.name == "User"
                        ? { columns: userColumns, rows }
                        : authUser?.role?.name == "Admin"
                        ? { columns: adminColumns, rows }
                        : { columns, rows }
                    }
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                <TablePagination
                  component="div"
                  count={totalUsers}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Customer;
