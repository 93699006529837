/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import RolesAPI from 'api/roles'

const getRoles = async () => {
    const services = new RolesAPI()
    const rolesName = []
       const resp = await services.GetAllRoles()
       if (resp?.data?.data?.roles) {
        const roles = resp?.data?.data?.roles
        roles?.map(item => {
            rolesName.push({name: item?.name, id: item?.id})
        })
       }
    
  return rolesName
}

export default getRoles