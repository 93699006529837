/* eslint-disable prettier/prettier */
import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setUserBalance,
} from "context";
import { Avatar, Badge, Button, Divider, MenuItem, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import BalanceAPI from "api/balance";
import ChangePassword from "examples/ChangePassword";
import AuthAPI from "api/auth";
import MDSnackbar from "components/MDSnackbar";
import MenuIcon from "@mui/icons-material/Menu";
import UserNotificationsPopup from "components/UserNotificationsPopup";
import UsersAPI from "../../../api/users";

function DashboardNavbar({ absolute, light, isMini }) {
  const navigate = useNavigate();
  const balanceService = new BalanceAPI();
  const authService = new AuthAPI();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode, userBalance } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [notificationsLists, setNotificationsLists] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const route = useLocation().pathname.split("/").slice(1);
  const authUser = JSON.parse(localStorage.getItem("user"));

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
 
    let activeInterval;
    getNotificationsCount();
    getNotificationsLists();
    if (activeInterval) {
      clearInterval(activeInterval);
    }

    activeInterval = setInterval(() => {
      getNotificationsCount();
    }, 10000);

    return () => {
      if (activeInterval) {
        clearInterval(activeInterval);
      }
   
    };
  }, []);

  const getNotificationsCount = async () => {
    let service = new UsersAPI();
    let { data } = await service.GetNotificationCount();
    if (data?.info?.isSuccess) {
      setNotificationCount(data?.data?.count);
      // setNotificationsLists(data?.data?.notifications);
    } else {
      // setNotificationsLists([]);
    }
  };

  const getNotificationsLists = async () => {
    let service = new UsersAPI();
    let { data } = await service.GetNotificationsLists();
    if (data?.info?.isSuccess) {
      setNotificationsLists(data?.data?.notifications);
    } else {
      setNotificationsLists([]);
    }
  };

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const getUserBalance = async () => {
    const resp = await balanceService.GetUserBalance();
    if (resp?.data?.data?.balance && resp?.data?.info?.isSuccess == true) {
      setUserBalance(dispatch, resp?.data?.data?.balance);
    }
  };

  useEffect(() => {
    getUserBalance();
  }, []);

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  const handleLogout = async () => {
    const user = localStorage.getItem("type");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    setAnchorEl(null);
    navigate(`/signin/${user}`);
  };

  const handleSubmitChangePassword = async () => {
    const payload = {
      data: {
        currentPassword: changePasswordData?.currentPassword,
        newPassword: changePasswordData?.newPassword,
      },
      userId: authUser?.id,
    };
    const resp = await authService.ChangePassword(payload);
    if (resp.data?.info?.isSuccess == true) {
      setIsOpenChangePassword(false);
      setChangePasswordData({
        currentPassword: "",
        newPassword: "",
      });
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
    } else {
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);

  const handleClickMenu = () => {
    handleMiniSidenav();
  };

  return (
    <>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="CHANGE PASSWORD"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <ChangePassword
        setIsOpenChangePassword={setIsOpenChangePassword}
        isOpenChangePassword={isOpenChangePassword}
        setChangePasswordData={setChangePasswordData}
        changePasswordData={changePasswordData}
        handleSubmitChangePassword={handleSubmitChangePassword}
      />
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleClickMenu}
              sx={{ ml: 2, display: { xl: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <MDBox sx={{ marginLeft: "auto" }} color={light ? "white" : "inherit"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  color: "#000",
                  gap: "1rem",
                }}
              >
                <div>
                  <MDTypography variant="h6">
                    {authUser?.role?.name == "Master Super Admin" ||
                    authUser?.role?.name == "SuperAdmin" ||
                    authUser?.role?.name == "User"
                      ? `${authUser?.username}`
                      : `${authUser?.username} (${userBalance})`}
                  </MDTypography>
                  {authUser?.role?.name != "Master Super Admin" && (
                    <div style={{ color: "#7b809a", fontSize: "15px", fontWeight: "300" }}>
                      {`Parent : ${authUser?.createdByUser?.username || ""}`}
                    </div>
                  )}
                </div>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={() => {
                    getNotificationsLists();
                    setShowNotification(true);
                  }}
                >
                  <Badge badgeContent={notificationCount} color="error">
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </Badge>
                </IconButton>
                {showNotification ? (
                  <UserNotificationsPopup
                    notificationsLists={notificationsLists}
                    handleClosePopup={() => {
                      setShowNotification(false);
                    }}
                  />
                ) : null}
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      src={
                        authUser?.logo != null ||
                        authUser?.logo != undefined ||
                        authUser?.logo != ""
                          ? authUser?.logo
                          : ""
                      }
                    ></Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={() => setIsOpenChangePassword(true)}>
                    <Avatar /> Change Password
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => handleLogout()}>
                    <Avatar /> Logout
                  </MenuItem>
                </Menu>
              </div>
              {renderMenu()}
            </MDBox>
          </MDBox>
        </Toolbar>
      </AppBar>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
