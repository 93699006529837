/* eslint-disable prettier/prettier */
import { Backdrop, Card, CircularProgress, Grid, Icon, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import getRoles from "utilities/getRoles";
import UsersAPI from "api/users";
import moment from "moment";

import { setUserBalance, useMaterialUIController } from "context";
import MDSnackbar from "components/MDSnackbar";
import { Helmet } from "react-helmet";
import PlansAPI from "api/plans";
import Swal from "sweetalert2";
import CreatePlans from "examples/CreatePlans";

const Plans = () => {
  const plansService = new PlansAPI();
  const [getAllPlans, setGetAllPlans] = useState([]);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [balance, setBalance] = useState("");
  const [discount, setDiscount] = useState(false);
  const [international, setInternational] = useState(false);
  const [status, setStatus] = useState(true);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  useEffect(() => {
    if (editRowData != null) {
      setName(editRowData?.name);
      setPrice(editRowData?.price);
      setBalance(editRowData?.balance);
      setDiscountPrice(editRowData?.discountPrice);
      setDiscount(editRowData?.isDiscount ? 1 : 0);
      setInternational(editRowData?.isInternational ? 1 : 0);
      setStatus(editRowData?.isActive ? 1 : 0);
    }
  }, [editRowData]);

  const handleStatusChange = (data, status, statusType) => {
    const payload = {
      name: data?.name,
      price: data?.price,
      discountPrice: data?.discountPrice,
      balance: data?.balance,
      isDiscount: statusType === "discount" ? (!status ? 1 : 0) : data?.isDiscount,
      isInternational: statusType === "international" ? (!status ? 1 : 0) : data?.isInternational,
      isActive: statusType === "active" ? (!status ? 1 : 0) : data?.isActive,
      isDeleted: statusType === "delete" ? (status ? 1 : 0) : 0,
    };
    handleUpdateStatus(payload, data?.id);
  };

  const handleUpdateStatus = async (payload, id) => {
    setIsLoading(true);
    const resp = await plansService.UpdatePlan(payload, id);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllPlansList();
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenCreateModal(false);
      setName("");
      setPrice("");
      setDiscountPrice("");
      setBalance("");
      setDiscount(false);
      setInternational(false);
      setStatus(true);
      setEditRowData(null);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const columns = [
    {
      Header: "Action",
      accessor: (rowData) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Icon
            fontSize="small"
            onClick={() => {
              setEditRowData(rowData);
              setIsOpenCreateModal(true);
            }}
            style={{ cursor: 'pointer', marginRight: '8px',color:'green' }}
          >
            edit
          </Icon>
          <Icon
            fontSize="small"
            onClick={(e) => {
              Swal.fire({
                title: `Plan Delete`,
                text: `You want to sure delete this plan?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1A73E8",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, Delete it!`,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleStatusChange(rowData, true, "delete");
                }
              });
            }}
            style={{ cursor: 'pointer', color: 'red' }}
          >
            delete
          </Icon>
        </div>
      ),

      align: "center",
    },
    {
      Header: "Name",
      accessor: (rowData) => {
        return <p>{rowData?.name}</p>;
      },
      align: "center",
    },
    {
      Header: "Price",
      accessor: (rowData) => {
        return <p>{rowData?.price}</p>;
      },
      align: "center",
    },
    {
      Header: "Discount Price",
      accessor: (rowData) => {
        return <p>{rowData?.discountPrice}</p>;
      },
      align: "center",
    },
    {
      Header: "balance",
      accessor: (rowData) => {
        return <p>{rowData?.balance}</p>;
      },
      align: "center",
    },
    {
      Header: "Discount",
      accessor: (rowData) => {
        return (
          <Switch
            checked={rowData?.isDiscount == 1 ? true : false}
            onChange={(e) => {
              Swal.fire({
                title: `Discount ${e.target.checked ? "Active" : "Inactive"}`,
                text: `You want to ${e.target.checked ? "Active" : "Inactive"} this file?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1A73E8",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, ${e.target.checked ? "Active" : "Inactive"} it!`,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleStatusChange(rowData, e.target.checked, "discount");
                }
              });
            }}
          />
        );
      },
      align: "center",
    },
    {
      Header: "International",
      accessor: (rowData) => {
        return (
          <Switch
            checked={rowData?.isInternational == 1 ? true : false}
            onChange={(e) => {
              Swal.fire({
                title: `International ${e.target.checked ? "Active" : "Inactive"}`,
                text: `You want to ${e.target.checked ? "Active" : "Inactive"} this file?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1A73E8",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, ${e.target.checked ? "Active" : "Inactive"} it!`,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleStatusChange(rowData, e.target.checked, "international");
                }
              });
            }}
          />
        );
      },
      align: "center",
    },
    {
      Header: "Status",
      accessor: (rowData) => {
        return (
          <Switch
            checked={rowData?.isActive == 1 ? true : false}
            onChange={(e) => {
              Swal.fire({
                title: `Plans ${e.target.checked ? "Active" : "Inactive"}`,
                text: `You want to ${e.target.checked ? "Active" : "Inactive"} this file?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1A73E8",
                cancelButtonColor: "#d33",
                confirmButtonText: `Yes, ${e.target.checked ? "Active" : "Inactive"} it!`,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleStatusChange(rowData, e.target.checked, "active");
                }
              });
            }}
          />
        );
      },
      align: "center",
    },

    {
      Header: "Creation Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
  ];
  const rows = getAllPlans;

  useEffect(() => {
    (async () => {
      getAllPlansList();
    })();
  }, []);

  const getAllPlansList = async () => {
    setIsLoading(true);
    const resp = await plansService.GetPlansLists();
    if (resp?.data?.data?.plans) {
      setGetAllPlans(resp?.data?.data?.plans);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitPlan = async () => {
    setIsLoading(true);

    const payload = {
      name: name,
      price: price,
      discountPrice: discountPrice,
      balance: balance,
      isDiscount: discount ? 1 : 0,
      isInternational: international ? 1 : 0,
      isActive: status ? 1 : 0,
      isDeleted: 0,
    };
    const resp = await plansService.CreatePlans(payload);

    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllPlansList();
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenCreateModal(false);
      setName("");
      setPrice("");
      setDiscountPrice("");
      setBalance("");
      setDiscount(false);
      setInternational(false);
      setStatus(true);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleUpdatePlan = async () => {
    setIsLoading(true);
    const payload = {
      name: name,
      price: price,
      discountPrice: discountPrice,
      balance: balance,
      isDiscount: discount ? 1 : 0,
      isInternational: international ? 1 : 0,
      isActive: status ? 1 : 0,
      isDeleted: 0,
    };
    const resp = await plansService.UpdatePlan(payload, editRowData?.id);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllPlansList();
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenCreateModal(false);
      setName("");
      setPrice("");
      setDiscountPrice("");
      setBalance("");
      setDiscount(false);
      setInternational(false);
      setStatus(true);
      setEditRowData(null);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);
  const TITLE = "Dmit Report | Plans";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="Plans"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <CreatePlans
        setIsOpenCreateModal={setIsOpenCreateModal}
        isOpenCreateModal={isOpenCreateModal}
        handleSubmitUser={handleSubmitPlan}
        setName={setName}
        name={name}
        tableTitle={"Plans"}
        isLoading={isLoading}
        price={price}
        setPrice={setPrice}
        discountPrice={discountPrice}
        setDiscountPrice={setDiscountPrice}
        balance={balance}
        setBalance={setBalance}
        discount={discount}
        setDiscount={setDiscount}
        international={international}
        setInternational={setInternational}
        status={status}
        setStatus={setStatus}
        setEditRowData={setEditRowData}
        editRowData={editRowData}
        handleUpdateUser={handleUpdatePlan}
      />

      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="table-header">
                    <MDTypography variant="h6" color="white">
                      Plans
                    </MDTypography>
                    <MDButton
                      variant="primary"
                      color="light"
                      size="small"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => setIsOpenCreateModal(true)}
                    >
                      Create
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                {/* <TablePagination
                  component="div"
                  count={totalUsers}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Plans;
