/* eslint-disable prettier/prettier */
import {
  Backdrop,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Switch,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import BalanceAPI from "api/balance";
import UsersAPI from "api/users";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setUserBalance } from "context";
import AddBalance from "examples/AddBalance";
import CreateUser from "examples/CreateUser";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import RejectedDays from "examples/RejectedDays";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import getRoles from "utilities/getRoles";

const RegularFranchises = () => {
  const [controller, dispatch] = useMaterialUIController();
  const service = new UsersAPI();
  const balanceService = new BalanceAPI();
  const authUser = JSON.parse(localStorage.getItem("user"));
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [roles, setRoles] = useState([]);
  const [name, setName] = useState("");
  const [addBalanceRow, setAddBalanceRow] = useState(null);
  const [addBalanceNumber, setAddBalanceNumber] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [editRowData, setEditRowData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rejectedDaysCount, setRejectedDaysCount] = useState("");
  const [rejectedDaysRow, setRejectedDaysRow] = useState(null);
  const [searchUserName, setSearchUserName] = useState("");
  const [planType, setPlanType] = useState("domestic")

  useEffect(() => {
    if (editRowData != null) {
      setName(editRowData?.name);
      setUserName(editRowData?.username);
      setUploadedFile(editRowData?.logo || null);
      setPlanType(editRowData?.isInternational == 1 ? "international" : "domestic")
    }
  }, [editRowData]);

  const handleStatusChange = async (rowData, checked) => {
    setIsLoading(true);
    const payload = {
      isActive: !checked,
      id: rowData?.id
    };
    const resp = await service.StatusChange(payload);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("regular");
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
      });
    }
  }

  const columns = [
    {
      Header: "Status",
      accessor: (rowData) => {
        return (
          <Switch checked={rowData?.isActive == 1 && rowData?.createdByUser?.isActive == 1 && rowData?.parentCreatedBy?.isActive == 1 ? true : false} onChange={(e) => {
            if (rowData?.createdByUser?.isActive == 1 && rowData?.parentCreatedBy?.isActive == 1) {
              if (authUser?.role?.name == "Master Super Admin" || authUser?.role?.name == "SuperAdmin") {
                Swal.fire({
                  title: `User ${e.target.checked ? "Active" : "Inactive"}`,
                  text: `You want to ${e.target.checked ? "Active" : "Inactive"} this file?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#1A73E8",
                  cancelButtonColor: "#d33",
                  confirmButtonText: `Yes, ${e.target.checked ? "Active" : "Inactive"} it!`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleStatusChange(rowData, e.target.checked)
                  }
                });
              }
            }
          }
          } />
        );
      },
      align: "center",
    },
    {
      Header: "User Name",
      accessor: (rowData) => {
        return (
          <Tooltip title={`Password: ${rowData?.password}`} placement="top">
            <p>{rowData?.username}</p>
          </Tooltip>
        );
      },
      align: "center",
    },
    {
      Header: "Creation Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "Parent",
      accessor: "createdByUser.username",
      align: "center",
    },
    {
      Header: "Balance",
      accessor: (rowData) => {
        return (
          <span
            onClick={() => {
              if (authUser?.role?.name != "Master Super Admin") {
                setAddBalanceRow(rowData);
              }
            }}
          >
            {" "}
            {rowData?.balance}
          </span>
        );
      },
      align: "center",
    },
    {
      Header: "rejected days",
      accessor: (rowData) => {
        return (
          <span
            onClick={() => {
              if (authUser?.role?.name == "Admin") {
                setRejectedDaysRow(rowData);
                setRejectedDaysCount(rowData?.rejectedDaysCount);
              }
            }}
          >
            {rowData?.rejectedDaysCount}
          </span>
        );
      },
      align: "center",
    },
    {
      Header: "logo",
      accessor: (rowData) => {
        return rowData?.logo != null && rowData?.logo != undefined && rowData?.logo != "" ? (
          <img src={rowData?.logo} height={25} width={25} alt="" />
        ) : (
          ""
        );
      },
      align: "center",
    },
    {
      Header: "Action",
      accessor: (rowData) =>
        authUser?.role?.name == "SuperAdmin" ? (
          <Icon
            fontSize="small"
            onClick={() => {
              setEditRowData(rowData);
              setIsOpenCreateModal(true);
            }}
          >
            edit
          </Icon>
        ) : (
          <Tooltip title={"Kindly Contact Your Admin"} placement="top">
            <Icon fontSize="small">edit</Icon>
          </Tooltip>
        ),
      align: "center",
    },
  ];
  const superAdminColumns = [
    {
      Header: "Status",
      accessor: (rowData) => {
        return (
          <Switch checked={rowData?.isActive == 1 && rowData?.createdByUser?.isActive == 1 && rowData?.parentCreatedBy?.isActive == 1 ? true : false} onChange={(e) => {
            if (rowData?.createdByUser?.isActive == 1 && rowData?.parentCreatedBy?.isActive == 1) {
              if (authUser?.role?.name == "Master Super Admin" || authUser?.role?.name == "SuperAdmin") {
                Swal.fire({
                  title: `User ${e.target.checked ? "Active" : "Inactive"}`,
                  text: `You want to ${e.target.checked ? "Active" : "Inactive"} this file?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#1A73E8",
                  cancelButtonColor: "#d33",
                  confirmButtonText: `Yes, ${e.target.checked ? "Active" : "Inactive"} it!`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleStatusChange(rowData, e.target.checked)
                  }
                });
              }
            }
          }
          } />
        );
      },
      align: "center",
    },
    {
      Header: "Payment Integration",
      accessor: (rowData) => {
          return (
            <Switch
              checked={rowData?.isPaymentIntegration == 1 ? true : false}
            />
          );
      },
      align: "center",
    },
    {
      Header: "User Name",
      accessor: (rowData) => {
        return (
          <Tooltip title={`Password: ${rowData?.password}`} placement="top">
            <p>{rowData?.username}</p>
          </Tooltip>
        );
      },
      align: "center",
    },
    {
      Header: "Creation Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "Parent",
      accessor: "createdByUser.username",
      align: "center",
    },
    {
      Header: "Balance",
      accessor: (rowData) => {
        return (
          <span
            onClick={() => {
              if (authUser?.role?.name != "Master Super Admin") {
                setAddBalanceRow(rowData);
              }
            }}
          >
            {" "}
            {rowData?.balance}
          </span>
        );
      },
      align: "center",
    },
    {
      Header: "rejected days",
      accessor: (rowData) => {
        return (
          <span
            onClick={() => {
              if (authUser?.role?.name == "Admin") {
                setRejectedDaysRow(rowData);
                setRejectedDaysCount(rowData?.rejectedDaysCount);
              }
            }}
          >
            {rowData?.rejectedDaysCount}
          </span>
        );
      },
      align: "center",
    },
    {
      Header: "logo",
      accessor: (rowData) => {
        return rowData?.logo != null && rowData?.logo != undefined && rowData?.logo != "" ? (
          <img src={rowData?.logo} height={25} width={25} alt="" />
        ) : (
          ""
        );
      },
      align: "center",
    },
    {
      Header: "Action",
      accessor: (rowData) =>
        authUser?.role?.name == "SuperAdmin" ? (
          <Icon
            fontSize="small"
            onClick={() => {
              setEditRowData(rowData);
              setIsOpenCreateModal(true);
            }}
          >
            edit
          </Icon>
        ) : (
          <Tooltip title={"Kindly Contact Your Admin"} placement="top">
            <Icon fontSize="small">edit</Icon>
          </Tooltip>
        ),
      align: "center",
    },
  ];
  const rows = getAllUsers;

  useEffect(() => {
    (async () => {
      getAllUsersList("regular");
    })();
  }, [page, rowsPerPage]);

  useEffect(() => {
    (async () => {
      const roles = await getRoles();
      setRoles(roles);
    })();
  }, []);

  const getAllUsersList = async (type = "", search = "") => {
    setIsLoading(true);
    const payload = {
      search: search,
      type: type,
      page: page + 1,
      pageSize: rowsPerPage,
    };
    const resp = await service.GetAllUsers(payload);
    if (resp?.data?.data?.users?.results && resp?.data?.info?.isSuccess == true) {
      setGetAllUsers(resp?.data?.data?.users?.results);
      setTotalUsers(resp?.data?.data?.users?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitUser = async () => {
    if (editRowData == null) {
      handleCreateUser();
    } else {
      handleUpdateUser();
    }
  };

  const handleCreateUser = async () => {
    setIsLoading(true);
    let adminRoleId = "";
    roles?.filter((item) => {
      if (item?.name == "Regular Franchise") {
        adminRoleId = item?.id;
      }
    });
    const type = JSON.parse(localStorage.getItem("user"));
    const payload = {
      // name: name,
      username: userName,
      password: "password",
      roleId: adminRoleId,
      type: "regular",
      logo: uploadedFile,
      isInternational: planType == "" ? false : planType == "domestic" ? false : true
    };
    const fm = new FormData();

    for (const key in payload) {
      fm.append(key, payload[key]);
    }
    const resp = await service.CreateUser(fm);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("regular");
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenCreateModal(false);
      setName("");
      setPlanType("")
      setUploadedFile(null);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleUpdateUser = async () => {
    setIsLoading(true);
    const payload = {
      // name: name,
      username: userName,
      logo: uploadedFile,
      rejectedDaysCount: Number(editRowData?.rejectedDaysCount),
      isInternational: planType == "" ? false : planType == "domestic" ? false : true
    };
    const fm = new FormData();

    for (const key in payload) {
      fm.append(key, payload[key]);
    }

 
    const resp = await service.UpdateUser(fm, editRowData?.id);

    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("regular");
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenCreateModal(false);
      setName("");
      setUserName("");
      setPlanType("")
      setEditRowData(null);
      setUploadedFile(null);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const getUserBalance = async () => {
    setIsLoading(true);
    const resp = await balanceService.GetUserBalance();
    if (resp?.data?.data?.balance && resp?.data?.info?.isSuccess == true) {
      setUserBalance(dispatch, resp?.data?.data?.balance);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitAddBalance = async () => {
    setIsLoading(true);
    const payload = {
      amount: Number(addBalanceNumber),
      userId: addBalanceRow?.id,
    };
    const resp = await balanceService.AddBalance(payload);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("regular");
      getUserBalance();
      setIsOpenCreateModal(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setAddBalanceRow(null);
      setAddBalanceNumber("");
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleSubmitRejectedDays = async () => {
    setIsLoading(true);
    const payload = {
      username: rejectedDaysRow?.username,
      logo: rejectedDaysRow?.logo,
      rejectedDaysCount: Number(rejectedDaysCount),
    };
    const fm = new FormData();

    for (const key in payload) {
      fm.append(key, payload[key]);
    }

    
    const resp = await service.UpdateUser(fm, rejectedDaysRow?.id);
   
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("regular");
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setRejectedDaysCount("");
      setRejectedDaysRow(null);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
              ? resp?.response?.data?.info?.message
              : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const debouncedHandleChange = useCallback(
    debounce((e) => {
      getAllUsersList("regular", e);
    }, 1000),
    []
  );

  const TITLE = "Dmit Report | Regular Franchises";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="REGULAR FRANCHISE"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <CreateUser
        setIsOpenCreateModal={setIsOpenCreateModal}
        isOpenCreateModal={isOpenCreateModal}
        handleSubmitUser={handleSubmitUser}
        setUserName={setUserName}
        setName={setName}
        name={name}
        userName={userName}
        tableTitle={"REGULAR FRANCHISE"}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        editRowData={editRowData}
        setEditRowData={setEditRowData}
        isLoading={isLoading}
        planType={planType}
        setPlanType={setPlanType}
      />
      <AddBalance
        addBalanceRow={addBalanceRow}
        setAddBalanceRow={setAddBalanceRow}
        setAddBalanceNumber={setAddBalanceNumber}
        addBalanceNumber={addBalanceNumber}
        handleSubmitAddBalance={handleSubmitAddBalance}
        isLoading={isLoading}
      />
      <RejectedDays
        rejectedDaysCount={rejectedDaysCount}
        setRejectedDaysCount={setRejectedDaysCount}
        setRejectedDaysRow={setRejectedDaysRow}
        rejectedDaysRow={rejectedDaysRow}
        handleSubmitRejectedDays={handleSubmitRejectedDays}
        isLoading={isLoading}
      />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="table-header">
                    <MDTypography variant="h6" color="white">
                      Regular Franchises
                    </MDTypography>
                    <div>
                      <TextField
                        id="outlined-basic"
                        className="header-filter-input"
                        value={searchUserName}
                        variant="outlined"
                        placeholder="Search User Name"
                        onChange={(e) => {
                          setSearchUserName(e.target.value);
                          debouncedHandleChange(e.target.value);
                        }}
                      />
                      {authUser?.role?.name != "Master Super Admin" &&
                        authUser?.role?.name != "SuperAdmin" ? (
                        <MDButton
                          variant="primary"
                          color="light"
                          size="small"
                          style={{ backgroundColor: "#fff" }}
                          onClick={() => setIsOpenCreateModal(true)}
                        >
                          Create
                        </MDButton>
                      ) : null}
                    </div>
                  </div>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                     table={authUser?.role?.name != "SuperAdmin" ? { columns, rows } : { columns: superAdminColumns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    nextPage={10}
                    pageOptions={[1, 2, , 3, 4, 5, 6, 7, 8, 9, 10]}
                  />
                </MDBox>
                <TablePagination
                  component="div"
                  count={totalUsers}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default RegularFranchises;
