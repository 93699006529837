/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import MDSnackbar from "components/MDSnackbar";

const CreatePayment = ({
  isOpenCreateModal,
  setIsOpenCreateModal,
  setName = () => {},
  email = "",
  setEmail = () => {},
  mobile = "",
  setMobile = () => {},
  name = "",
  tableTitle = "",
  isLoading = false,
  handleSubmitUser = () => {},
  editRowData = null,
  setEditRowData = () => {},
}) => {
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const style = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePlanSubmit = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "Name is required.";
    tempErrors.email = email && validateEmail(email) ? "" : "A valid email is required.";
    tempErrors.mobile =
      mobile && mobile.length === 10 && /^[0-9]*$/.test(mobile)
        ? ""
        : "Mobile number must be exactly 10 digits and only contain numbers.";
    setErrors(tempErrors);

    if (Object.values(tempErrors).every((x) => x === "")) {
      handleSubmitUser();
    }
  };
  return (
    <>
      <MDSnackbar
        color={"error"}
        icon={"close"}
        title="File name does not match the user name."
        content={"The file which you have selected does not match with the User's name"}
        open={snackbarOpen}
        onClose={() => handleSnackbarClose()}
        close={() => handleSnackbarClose()}
        bgWhite
      />
      <Modal
        open={isOpenCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size=""
      >
        <Box sx={style}>
          <Backdrop
            sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <MDButton
            variant="primary"
            color="light"
            size="small"
            style={{ backgroundColor: "#fff", position: "absolute", top: "10px", right: "0" }}
            onClick={() => {
              setIsOpenCreateModal(false);
              setName("");
              setErrors("");
              setEditRowData(null);
              setMobile("");
              setEmail("");
            }}
          >
            <Icon fontSize="small">close</Icon>
          </MDButton>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ marginBottom: "1rem" }}
          >
            {tableTitle}
          </Typography>
          <div className="modal-content">
            <Typography variant="body1" sx={{ marginBottom: "0.5rem" }}>
              <strong>Plan Name: </strong>
              {editRowData?.name}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: "0.5rem" }}>
              <strong>Price: </strong>
              &#8377; {editRowData?.discountPrice == 0 ? editRowData?.price : editRowData?.discountPrice}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: "0.5rem" }}>
              <strong>Balance: </strong>
              {editRowData?.balance}
            </Typography>
            <TextField
              id="outlined-basic"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              value={email}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
              margin="normal"
            />
            <TextField
              id="outlined-basic"
              label="Mobile"
              value={mobile}
              variant="outlined"
              onChange={(e) => setMobile(e.target.value)}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={!!errors.mobile}
              helperText={errors.mobile}
              fullWidth
              margin="normal"
            />
          </div>
          <div className="create-new-btn-container">
            <Button variant="contained" sx={{ color: "#fff" }} onClick={() => handlePlanSubmit()}>
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreatePayment;
