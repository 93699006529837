/* eslint-disable prettier/prettier */
import { Backdrop, Card, CircularProgress, Grid, TablePagination } from "@mui/material";
import BalanceAPI from "api/balance";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { setUserBalance } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Transaction = () => {
  const balanceService = new BalanceAPI();
  const [controller, dispatch] = useMaterialUIController();
  const [allTransactionHistory, setAllTransactionHistory] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const columns = [
    {
      Header: "Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "left",
    },
    {
      Header: "Sender",
      accessor: (rowData) => {
        return rowData?.type == "phonePe" ? "Phone Pe" : <p>{rowData?.createdByUser == null ? "-" : rowData?.createdByUser?.username}</p>;
      },
      align: "left",
    },
    {
      Header: "Receiver",
      accessor: (rowData) => {
        return <p>{rowData?.user == null ? "-" : rowData?.user?.username}</p>;
      },
      align: "left",
    },
    {
      Header: "Amount",
      accessor: (rowData) => {
        return (
          <span
            style={
              rowData?.credit == 0
                ? { color: "red", fontWeight: "600" }
                : { color: "green", fontWeight: "600" }
            }
          >
            {rowData?.credit == 0 ? rowData?.debit : rowData?.credit}
          </span>
        );
      },
      align: "left",
    },
    {
      Header: "Description",
      accessor: "comment",
      align: "left",
    },
  ];

  useEffect(() => {
    getTransactionHistory();
  }, [page, rowsPerPage]);

  useEffect(() => {
    getUserBalance()
  }, [])
  

  const getTransactionHistory = async () => {
    setIsLoading(true);
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
    };
    const resp = await balanceService.GetBalanceHistory(payload);
    if (resp?.data?.data?.results && resp?.data?.info?.isSuccess == true) {
      setAllTransactionHistory(resp?.data?.data?.results);
      setTotalUsers(resp?.data?.data?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const rows = allTransactionHistory;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const getUserBalance = async () => {
    setIsLoading(true);
    const resp = await balanceService.GetUserBalance();
    if (resp?.data?.data?.balance && resp?.data?.info?.isSuccess == true) {
      setUserBalance(dispatch, resp?.data?.data?.balance);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);
  const TITLE = "Dmit Report | Transaction";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="MESSAGES"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="table-header">
                    <MDTypography variant="h6" color="white">
                      Transactions
                    </MDTypography>
                  </div>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                <TablePagination
                  component="div"
                  count={totalUsers}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Transaction;
