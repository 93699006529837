/* eslint-disable prettier/prettier */
import {
  Backdrop,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Pagination,
  Snackbar,
  TablePagination,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import CreateUser from "examples/CreateUser";
import getRoles from "utilities/getRoles";
import UsersAPI from "api/users";
import moment from "moment";
import AddBalance from "examples/AddBalance";
import BalanceAPI from "api/balance";
import { setUserBalance, useMaterialUIController } from "context";
import MDSnackbar from "components/MDSnackbar";
import MessagesAPI from "api/messages";
import ReplyIcon from "@mui/icons-material/Reply";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import MessageReply from "examples/MessageReply";
import { Helmet } from "react-helmet";

const Messages = () => {
  const [controller, dispatch] = useMaterialUIController();
  const messagesService = new MessagesAPI();
  const authUser = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [getAllMessages, setGetAllMessages] = useState([]);
  const [totalMessages, setTotalMessages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [isOpenReplyBox, setIsOpenReplyBox] = useState({
    isOpen: false,
    row: {},
  });
  const [replyData, setReplyData] = useState({
    title: "",
    description: "",
  });
  const columns = [
    {
      Header: "Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "From",
      accessor: "fromUser.username",
      align: "center",
    },
    {
      Header: "To",
      accessor: "toUser.username",
      align: "center",
    },
    {
      Header: "Title",
      accessor: "title",
      align: "center",
    },
    {
      Header: "File",
      accessor: (rowData) => {
        if (rowData?.file != null && rowData?.file != undefined) {
          return (
             <MDButton
             color="info"
             variant="contained"
             component="label"
             onClick={() => window.open(rowData?.file)}
           >
             Download File
           </MDButton>
          );
        }
      },
      align: "center",
    },
    {
      Header: "description",
      accessor: "description",
      align: "center",
    },
    {
      Header: "Reply",
      accessor: (rowData) => {
        if (rowData?.from != authUser?.id) {
          return (
            <ReplyIcon
              sx={{ cursor: "pointer" }}
              fontSize="large"
              color="info"
              onClick={() =>
                setIsOpenReplyBox({
                  isOpen: true,
                  row: rowData,
                })
              }
            />
          );
        }
      },
      align: "center",
    },
    {
      Header: " ",
      accessor: (rowData) => {
        return rowData?.isRead == 1 ? <MarkChatReadIcon fontSize="large" color="success" /> : "";
      },
      align: "center",
    },
  ];
  const rows = getAllMessages;

  useEffect(() => {
    (async () => {
      getMessagesList();
    })();
  }, [page, rowsPerPage]);

  const getMessagesList = async () => {
    setIsLoading(true);
    const payload = {
      page: page + 1,
      pageSize: rowsPerPage,
    };
    const resp = await messagesService.GetMessages(payload);

    if (resp?.data?.data?.results && resp?.data?.info?.isSuccess == true) {
      setGetAllMessages(resp?.data?.data?.results);
      setTotalMessages(resp?.data?.data?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleSubmitReply = async () => {
    setIsLoading(true);
    const payload = {
      from: authUser?.id,
      to: isOpenReplyBox?.row?.fromUser?.id,
      title: replyData?.title,
      description: replyData?.description,
    };
    const resp = await messagesService.SendMessages(payload);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getMessagesList();
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenReplyBox({
        isOpen: false,
        row: {},
      });
      setReplyData({
        title: "",
        description: "",
      });
    } else {
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);

  const TITLE = "Dmit Report | Messages";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="MESSAGES"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <MessageReply
        setIsOpenReplyBox={setIsOpenReplyBox}
        isOpenReplyBox={isOpenReplyBox}
        replyData={replyData}
        setReplyData={setReplyData}
        handleSubmitReply={handleSubmitReply}
        isLoading={isLoading}
      />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="table-header">
                    <MDTypography variant="h6" color="white">
                      Messages
                    </MDTypography>
                  </div>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                <TablePagination
                  component="div"
                  count={totalMessages}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Messages;
