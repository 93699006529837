/* eslint-disable */
import { reportRoutes } from "constant/apiRoutes";
import apiClient from "utilities/configureAxios";
class ReportAPI {
  constructor() {
    this.client = apiClient;
  }

  GetMessages = async (payload = {}) => {
    try {
      const res = await this.client.get(
        `${reportRoutes.CUSTOMER_REPORTS}?page=${payload?.page}&pageSize=${payload?.pageSize}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`
      );

      return res;
    } catch (error) {
      return error;
    }
  };
}

export default ReportAPI;
