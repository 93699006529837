/* eslint-disable */
import { useEffect, useState, useRef } from "react";
import style from "./index.module.scss";
import { Skeleton } from "antd";
import moment from "moment";
import _ from "lodash";
import { redirect, useLocation, useNavigate } from "react-router-dom";

const IndividualNotification = ({
  cta,
  ctaText,
  icon,
  id,
  isMarkedRead,
  isNew,
  isUnreadtext,
  text,
  time,
  fromUsername,
  handleClosePopup,
}) => {
  const navigate = useNavigate();
  const handleCtaRedirect = () => {
    handleClosePopup();
    window.open("/messages", "_self");
  };
  return (
    <div className={style["individual-notification"]}>
      <div className={style["sub-container"]}>
        <div className={style.pic} style={{ backgroundImage: `url(${icon})` }} />
        <div>
          <div className={style.time}>{fromUsername}</div>
          <div className={style.text}>{text}</div>
          <div className={style.time}>{moment(time).format("DD MMM, YYYY")}</div>
        </div>
      </div>
      <div className={style["cta-container"]}>
        {cta ? (
          <div
            className={style["cta-btn"]}
            style={{ zIndex: 10 }}
            onClick={() => handleCtaRedirect()}
          >
            <button type="button" onClick={() => handleCtaRedirect()}>
              {ctaText}
            </button>
          </div>
        ) : null}
        {isNew && <div className={style.dot} />}
      </div>
    </div>
  );
};

const UserNotificationsPopup = ({ notificationsLists = [], handleClosePopup }) => {
  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef?.current?.contains(event.target)) {
        handleClosePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleNotificationsClick = () => {
    window.open("/messages", "_self");
    // navigate("/messages");
  };

  useEffect(() => {
    // dispatch({ type: userActions.GET_NOTIFICATIONS });
  }, []);

  const loadingNotifications = false;

  return (
    <div className={style.container} ref={dropdownRef}>
      <div className={style.heading}>
        Notifications
        {loadingNotifications ? null : (
          <div className={style.filter} onClick={handleNotificationsClick}>
            See all
          </div>
        )}
      </div>

      {/* <div className={style['filter-container']}>
        <div className={style['sub-container']}>
          <div className={style.filter}>All</div>
          <div className={style.filter}>Unread</div>
        </div>
      </div> */}
      <div className={style["notification-data"]}>
        {loadingNotifications ? (
          <div className="skeleton-loading-container">
            {_.range(0, 3).map((item, index) => (
              <Skeleton.Button
                key={`noti-${item}`}
                loading={true}
                active={true}
                size={"large"}
                className={"skeleton-loading"}
              />
            ))}
          </div>
        ) : (
          <>
            {notificationsLists?.length === 0 ? (
              <div className={style.empty}> No notifications to show</div>
            ) : (
              <>
                {notificationsLists?.slice(0, 4).map((item, index) => (
                  <IndividualNotification
                    cta={true}
                    ctaText={"Review"}
                    icon={item?.fromUser?.logo}
                    fromUsername={item?.fromUser?.username}
                    id={item?.id}
                    isMarkedRead={item?.isRead}
                    isNew={item?.isRead ? false : true}
                    time={item?.created_at}
                    text={item?.title}
                    handleClosePopup={() => handleClosePopup()}
                  />
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserNotificationsPopup;
