/* eslint-disable */
import { usersRoutes } from "../../constant/apiRoutes";
import apiClient from "utilities/configureAxios";
class UsersAPI {
  constructor() {
    this.client = apiClient;
  }

  GetAllUsers = async (payload = {}) => {
    try {
      const res = await this.client.get(
        `${usersRoutes.GET_ALL_USERS}?search=${payload?.search || ""}&type=${payload?.type}&page=${payload?.page}&pageSize=${payload?.pageSize}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };
  CreateUser = async (payload = {}) => {
    try {
      const res = await this.client.post(usersRoutes.CREATE_USER, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  StatusChange = async (payload = {}) => {
    try {
      const res = await this.client.post(usersRoutes.STATUS_CHANGE, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  PaymentIntegrationStatusChange = async (payload = {}) => {
    try {
      const res = await this.client.post(usersRoutes.PAYMENT_INTEGRATION_CHANGE, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
  UpdateUser = async (payload = {}, userId = "") => {
    try {
      const res = await this.client.put(`${usersRoutes.UPDATE_USER}/${userId}`, payload);
      return res;
    } catch (error) {
      return error;
    }
  };  
  GetNotificationCount = async (payload = {}) => {
    try {
      const res = await this.client.get(`${usersRoutes.NOTIFICATION_COUNT}`);
      return res;
    } catch (error) {
      return error;
    }
  };
  GetNotificationsLists = async (payload = {}) => {
    try {
      const res = await this.client.get(`${usersRoutes.NOTIFICATION_LISTS}`);
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default UsersAPI;
