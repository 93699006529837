/* eslint-disable */
import { balanceRoutes } from "constant/apiRoutes";
import apiClient from "utilities/configureAxios";
class BalanceAPI {
  constructor() {
    this.client = apiClient;
  }

  AddBalance = async (payload = {}) => {
    try {
      const res = await this.client.post(balanceRoutes.ADD_BALANCE, payload);
      return res;
    } catch (error) {
      return error;
    }
  };

  GetUserBalance = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.role?.name != "Master Super Admin") {
      try {
        const res = await this.client.get(balanceRoutes.GET_USER_BALANCE);
        return res;
      } catch (error) {
        return error;
      }
    }
  };

  GetBalanceHistory = async (payload = {}) => {
    try {
      const res = await this.client.get(
        `${balanceRoutes.GET_BALANCE_HISTORY}?page=${payload?.page}&pageSize=${payload?.pageSize}`
      );
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default BalanceAPI;
