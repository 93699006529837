/* eslint-disable prettier/prettier */
import { Backdrop, Card, CircularProgress, Grid, Icon, Pagination, Snackbar, TablePagination, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import CreateUser from "examples/CreateUser";
import getRoles from "utilities/getRoles";
import UsersAPI from "api/users";
import moment from "moment";
import AddBalance from "examples/AddBalance";
import BalanceAPI from "api/balance";
import { setUserBalance, useMaterialUIController } from "context";
import MDSnackbar from "components/MDSnackbar";
import { Helmet } from "react-helmet";

const SuperAdmin = () => {
  const [controller, dispatch] = useMaterialUIController();
  const userService = new UsersAPI();
  const balanceService = new BalanceAPI();
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [roles, setRoles] = useState([]);
  const [name, setName] = useState("");
  const [addBalanceRow, setAddBalanceRow] = useState(null);
  const [addBalanceNumber, setAddBalanceNumber] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      Header: "User Name",
      accessor: (rowData) =>
        {
          return (
          <Tooltip title={`Password: ${rowData?.password}`} placement="top">
            <p>
            {rowData?.username}
            </p>
          </Tooltip>
         )
        },
      align: "center",
    },
    {
      Header: "Creation Date",
      accessor: (rowData) => {
        return moment(rowData?.created_at).format("DD-MM-YYYY");
      },
      align: "center",
    },
    {
      Header: "Parent",
      accessor: "createdByUser.username",
      align: "center",
    },
    {
      Header: "logo",
      accessor: (rowData) => {
        return rowData?.logo != null && rowData?.logo != undefined && rowData?.logo != "" ? (
          <img src={rowData?.logo} height={25} width={25} alt="" />
        ) : (
          ""
        );
      },
      align: "center",
    },
  ];
  const rows = getAllUsers;

  useEffect(() => {
    (async () => {
      getAllUsersList("superAdmin");
    })();
  }, [page, rowsPerPage]);

  useEffect(() => {
    (async () => {
      const roles = await getRoles();
      setRoles(roles);
    })();
  }, []);

  const getAllUsersList = async (type = "") => {
    setIsLoading(true);
    const payload = {
      type: type,
      page: page + 1,
      pageSize: rowsPerPage,
    };
    const resp = await userService.GetAllUsers(payload);
    if (resp?.data?.data?.users?.results && resp?.data?.info?.isSuccess == true) {
      setGetAllUsers(resp?.data?.data?.users?.results);
      setTotalUsers(resp?.data?.data?.users?.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitUser = async () => {
    setIsLoading(true);
    let adminRoleId = "";
    roles?.filter((item) => {
      if (item?.name == "SuperAdmin") {
        adminRoleId = item?.id;
      }
    });
    const type = JSON.parse(localStorage.getItem("user"));
    const payload = {
      // name: name,
      username: userName,
      password: "password",
      roleId: adminRoleId,
      type: "superAdmin",
      logo: uploadedFile
    };
    const fm = new FormData();

    for (const key in payload) {
      fm.append(key, payload[key]);
    }
    const resp = await userService.CreateUser(fm);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("superAdmin");
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setIsOpenCreateModal(false);
      setName("");
      setUploadedFile(null)
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const getUserBalance = async () => {
    setIsLoading(true);
    const resp = await balanceService.GetUserBalance();
    if (resp?.data?.data?.balance && resp?.data?.info?.isSuccess == true) {
      setUserBalance(dispatch, resp?.data?.data?.balance);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitAddBalance = async () => {
    setIsLoading(true);
    const payload = {
      amount: Number(addBalanceNumber),
      userId: addBalanceRow?.id,
    };
    const resp = await balanceService.AddBalance(payload);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoading(false);
      getAllUsersList("superAdmin");
      getUserBalance();
      setIsOpenCreateModal(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "success",
        content: resp?.data?.info?.message,
      });
      setAddBalanceRow(null);
      setAddBalanceNumber("");
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);
  const TITLE = "Dmit Report | Super Admin";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="SUPER ADMIN"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <CreateUser
        setIsOpenCreateModal={setIsOpenCreateModal}
        isOpenCreateModal={isOpenCreateModal}
        handleSubmitUser={handleSubmitUser}
        setUserName={setUserName}
        setName={setName}
        name={name}
        userName={userName}
        tableTitle={"SUPER ADMIN"}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        isLoading={isLoading}
      />
      <AddBalance
        addBalanceRow={addBalanceRow}
        setAddBalanceRow={setAddBalanceRow}
        setAddBalanceNumber={setAddBalanceNumber}
        addBalanceNumber={addBalanceNumber}
        handleSubmitAddBalance={handleSubmitAddBalance}
        isLoading={isLoading}
      />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="table-header">
                    <MDTypography variant="h6" color="white">
                      Super Admin
                    </MDTypography>
                    <MDButton
                      variant="primary"
                      color="light"
                      size="small"
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => setIsOpenCreateModal(true)}
                    >
                      Create
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
                <TablePagination
                  component="div"
                  count={totalUsers}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default SuperAdmin;
