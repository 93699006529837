/* eslint-disable */
import { dashBoardRoutes } from "constant/apiRoutes";
import { balanceRoutes } from "constant/apiRoutes";
import apiClient from "utilities/configureAxios";
class DashBoardAPI {
  constructor() {
   
    this.client = apiClient;
  }
  GetCount = async () => {
    try {
      const res = await this.client.get(dashBoardRoutes.GET_COUNT);
      return res;
    } catch (error) {
      return error;
    }
  };
  GetGraphCount = async (payload = {}) => {
    try {
      const res = await this.client.post(dashBoardRoutes.GRAPH_COUNT, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default DashBoardAPI;
