/* eslint-disable */
import { plansRoutes, usersRoutes } from "../../constant/apiRoutes";
import apiClient from "utilities/configureAxios";
class PlansAPI {
  constructor() {
    this.client = apiClient;
  }

  GetPlansLists = async (payload = {}) => {
    try {
      const res = await this.client.get(`${plansRoutes.GET_ALL_PLANS}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  CreatePlans = async (payload = {}) => {
    try {
      const res = await this.client.post(plansRoutes.CREATE_PLANS, payload);
      return res;
    } catch (error) {
      return error;
    }
  };

  UpdatePlan = async (payload = {}, userId = "") => {
    try {
      const res = await this.client.put(`${plansRoutes.UPDATE_PLANS}/${userId}`, payload);
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default PlansAPI;
