/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import MDSnackbar from "components/MDSnackbar";

const CreatePlans = ({
  isOpenCreateModal,
  setIsOpenCreateModal,
  setName = () => {},
  price = "",
  setPrice = () => {},
  discountPrice = "",
  setDiscountPrice = () => {},
  discount = "",
  setDiscount = () => {},
  international = "",
  setInternational = () => {},
  status = "",
  setStatus = () => {},
  balance = "",
  setBalance = () => {},
  name = "",
  tableTitle = "",
  isLoading = false,
  setEditRowData = () => {},
  handleSubmitUser = () => {},
  handleUpdateUser = () => {},
  editRowData = null,
}) => {
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const style = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  const handleNumberInputChange = (setter) => (event) => {
    const value = event.target.value;
    if (!value || /^[0-9]*$/.test(value)) {
      setter(value);
    }
  };
  const handlePlanSubmit = () => {
    let tempErrors = {};
    tempErrors.name = name ? "" : "User Name is required.";
    tempErrors.price = price && !isNaN(price) ? "" : "Price is required and must be a number.";
    tempErrors.discountPrice =
      discountPrice && !isNaN(discountPrice)
        ? ""
        : "Discount Price is required and must be a number.";
    tempErrors.balance =
      balance && !isNaN(balance) ? "" : "Balance is required and must be a number.";
    setErrors(tempErrors);

    if (Object.values(tempErrors).every((x) => x === "")) {
      if (editRowData == null) {
        handleSubmitUser();
      } else {
        handleUpdateUser();
      }
    }
  };
  return (
    <>
      <MDSnackbar
        color={"error"}
        icon={"close"}
        title="File name does not match the user name."
        content={"The file which you have selected does not match with the User's name"}
        open={snackbarOpen}
        onClose={() => handleSnackbarClose()}
        close={() => handleSnackbarClose()}
        bgWhite
      />
      <Modal
        open={isOpenCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size=""
      >
        <Box sx={style}>
          <Backdrop
            sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <MDButton
            variant="primary"
            color="light"
            size="small"
            style={{ backgroundColor: "#fff", position: "absolute", top: "10px", right: "0" }}
            onClick={() => {
              setIsOpenCreateModal(false);
              setName("");
              setEditRowData(null);
              setBalance("");
              setPrice("");
              setDiscountPrice("");
              setDiscount(false);
              setInternational(false);
              setStatus(true);
              setErrors("");
            }}
          >
            <Icon fontSize="small">close</Icon>
          </MDButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "1rem" }}
          >
            {tableTitle}
          </Typography>
          <div className="modal-content">
            <TextField
              id="outlined-basic"
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              id="outlined-basic"
              label="Price"
              value={price}
              variant="outlined"
              onChange={handleNumberInputChange(setPrice)}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={!!errors.price}
              helperText={errors.price}
            />
            <TextField
              id="outlined-basic"
              label="Discount Price"
              value={discountPrice}
              variant="outlined"
              onChange={handleNumberInputChange(setDiscountPrice)}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={!!errors.discountPrice}
              helperText={errors.discountPrice}
            />
            <TextField
              id="outlined-basic"
              label="Balance"
              value={balance}
              variant="outlined"
              onChange={handleNumberInputChange(setBalance)}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={!!errors.balance}
              helperText={errors.balance}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={discount}
                    onChange={(e) => setDiscount(e.target.checked)}
                    name="discount"
                    color="primary"
                  />
                }
                label="DISCOUNT"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={international}
                    onChange={(e) => setInternational(e.target.checked)}
                    name="international"
                    color="primary"
                  />
                }
                label="INTERNATIONAL"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                    name="status"
                    color="primary"
                  />
                }
                label="STATUS"
              />
            </Box>
          </div>
          <div className="create-new-btn-container">
            <Button variant="contained" sx={{ color: "#fff" }} onClick={() => handlePlanSubmit()}>
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreatePlans;
