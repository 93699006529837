/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from "react";
import { Card, CircularProgress, Divider, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Bar } from "react-chartjs-2";
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";
import { DateRangePicker } from "rsuite";
import DashBoardAPI from "api/dashBoard";
import { predefinedRanges } from "utilities/predefinedRanges";

const User = () => {
  const dashBoardServices = new DashBoardAPI();
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [countList, setCountList] = useState({});
  const [filterDates, setFilterDates] = useState([
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);
  const [graphCountList, setGraphCountList] = useState({
    data: {
      labels: [],
      datasets: [
        {
          label: "",
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: "",
          data: [],
          maxBarThickness: 6,
        },
      ],
    },
    options: {},
  });

  useEffect(() => {
    getDashBoardCount();
  }, []);

  useEffect(() => {
    getDashBoardGraphCount();
  }, [filterDates]);

  const getDashBoardCount = async () => {
    setIsLoadingCount(true);
    const resp = await dashBoardServices.GetCount();
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoadingCount(false);
      setCountList(resp?.data?.data);
    } else {
      setIsLoadingCount(false);
    }
  };

  function removeNullProps(obj) {
    for (let prop in obj) {
      if (obj[prop] === null) {
        delete obj[prop];
      } else if (typeof obj[prop] === "object") {
        removeNullProps(obj[prop]);
      }
    }
  }

  const getDashBoardGraphCount = async () => {
    setIsLoadingGraph(true);
    const payload = {
      dateto: filterDates?.length ? filterDates[1] : null,
      datefrom: filterDates?.length ? filterDates[0] : null,
    };
    const resp = await dashBoardServices.GetGraphCount(payload);
    if (resp?.data?.info?.isSuccess == true) {
      setIsLoadingGraph(false);
      removeNullProps(resp?.data?.data);
      let transformedData = {};
      for (let key in resp?.data?.data) {
        let newKey = key.replace(/([A-Z])/g, " $1").trim();
        newKey = newKey.charAt(0).toUpperCase() + newKey.slice(1);
        transformedData[newKey] = resp?.data?.data[key];
      }
      const response = transformedData;
      let labels;
      // if (response?.customerCount) {
      //   labels = Object.keys(response)?.concat(Object.keys(response?.customerCount));
      // } else {
      labels = Object.keys(response);
      // }
      const result = [];
      for (const key in response) {
        if (key == "Customer") {
          result.push(
            Object.values(response["Customer"] || {})?.reduce((total, count) => total + count, 0)
          );
        } else {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            if ("count" in response[key]) {
              result.push(response[key].count);
            }
            // else {
            //   for (const status in response[key]) {
        
            //     if (Object.prototype.hasOwnProperty.call(response[key], status)) {
            //       result.push(response[key][status]);
            //     }
            //   }
            // }
          }
        }
      }
      const dataSet = {
        label: "Created",
        data: result,
      };
      const { data, options } = await configs(labels || [], dataSet || {});
      setGraphCountList({
        data: data,
        options: options,
      });
    } else {
      setIsLoadingGraph(false);
    }
  };

  return (
    <MDBox py={3}>
      <Grid container spacing={3} justifyContent={"center"}>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="success"
              icon="weekend"
              title="Completed Customers"
              count={countList?.customerCount?.Completed || 0}
              isLoadingCount={isLoadingCount}
              percentage={{
                color: "success",
                amount: "+55%",
                label: "than lask week",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="warning"
              icon="leaderboard"
              title="Pending Customers"
              count={countList?.customerCount?.Pending || 0}
              isLoadingCount={isLoadingCount}
              percentage={{
                color: "warning",
                amount: "+3%",
                label: "than last month",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="error"
              icon="store"
              title="Rejected Customers"
              count={countList?.customerCount?.Rejected || 0}
              isLoadingCount={isLoadingCount}
              percentage={{
                color: "error",
                amount: "+1%",
                label: "than yesterday",
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      {/* <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrdersOverview />
          </Grid>
        </Grid>
      </MDBox> */}
      <MDBox mt={4.5}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              <Card sx={{ height: "100%", width: "100%" }}>
                <MDBox padding="1rem">
                  <DateRangePicker
                    ranges={predefinedRanges}
                    format="dd-MM-yyyy"
                    showOneCalendar
                    value={filterDates}
                    placeholder="Select Filter Date"
                    style={{ width: 300 }}
                    onChange={(e) => setFilterDates(e)}
                    onClean={() =>
                      setFilterDates([
                        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                        new Date(),
                      ])
                    }
                  />
                  {useMemo(
                    () => (
                      <MDBox
                        variant="gradient"
                        bgColor={isLoadingGraph == false ? "info" : ""}
                        borderRadius="lg"
                        coloredShadow={isLoadingGraph == false ? "info" : ""}
                        py={2}
                        pr={0.5}
                        mt={3}
                        height="20rem"
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        {isLoadingGraph == false ? (
                          graphCountList?.data?.labels?.length ? (
                            <Bar
                              data={graphCountList?.data}
                              options={graphCountList?.options}
                              redraw
                            />
                          ) : null
                        ) : (
                          <CircularProgress size="50px" color="info" />
                        )}
                      </MDBox>
                    ),
                    ["info", graphCountList?.data, graphCountList?.options, isLoadingGraph]
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default User;
