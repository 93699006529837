/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import { initialUserName } from "utilities/userNameInitialState";

const RejectReason = ({
  isOpenRejectCustomerPopup,
  setIsOpenRejectCustomerPopup,
  tableTitle,
  rejectReason,
  setRejectReason,
  isLoading = false,
  handleSubmitRejectReason = () => {},
}) => {
  const style = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };


  return (
    <Modal
      open={isOpenRejectCustomerPopup?.isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      size=""
    >
      <Box sx={style}>
        <Backdrop
          sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MDButton
          variant="primary"
          color="light"
          size="small"
          style={{ backgroundColor: "#fff", position: "absolute", top: "10px", right: "0" }}
          onClick={() => {
            setIsOpenRejectCustomerPopup({
              isOpen: false,
              row: {},
            });
          }}
        >
          <Icon fontSize="small">close</Icon>
        </MDButton>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ marginBottom: "1rem" }}
        >
          {tableTitle}
        </Typography>
        <div className="modal-content">
          <TextField
            id="outlined-basic"
            label="Created User"
            value={isOpenRejectCustomerPopup?.row?.createdUser?.username}
            variant="outlined"
            disabled
          />
          <TextField
            id="outlined-basic"
            label="Description"
            value={rejectReason?.description}
            variant="outlined"
            onChange={(e) => {
              setRejectReason(e.target.value);
            }}
          />
        </div>
        <div className="create-new-btn-container">
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            onClick={() => handleSubmitRejectReason()}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default RejectReason;
