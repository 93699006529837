/* eslint-disable react/prop-types */
import { Box, Button, Icon, Modal, TextField, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useState } from "react";

const ChangePassword = ({
  isOpenChangePassword,
  setIsOpenChangePassword,
  changePasswordData,
  setChangePasswordData,
  handleSubmitChangePassword = () => {},
}) => {
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const style = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };
  return (
    <Modal
      open={isOpenChangePassword}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      size=""
    >
      <Box sx={style}>
        <MDButton
          variant="primary"
          color="light"
          size="small"
          style={{ backgroundColor: "#fff", position: "absolute", top: "10px", right: "0" }}
          onClick={() => {
            setIsOpenChangePassword(false);
          }}
        >
          <Icon fontSize="small">close</Icon>
        </MDButton>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ marginBottom: "1rem" }}
        >
          CHANGE PASSWORD
        </Typography>
        <div className="modal-content">
          <TextField
            type="text"
            id="outlined-basic"
            label="Current Password"
            value={changePasswordData?.currentPassword}
            variant="outlined"
            onChange={(e) => {
              setChangePasswordData((prevState) => ({
                ...prevState,
                currentPassword: e.target.value,
              }));
            }}
          />
          <TextField
            id="outlined-basic"
            label="New Password"
            type="password"
            value={changePasswordData?.newPassword}
            variant="outlined"
            onChange={(e) => {
              setChangePasswordData((prevState) => ({
                ...prevState,
                newPassword: e.target.value,
              }));
            }}
          />
          <TextField
            id="outlined-basic"
            type="password"
            label="Confirm New Password"
            value={confirmNewPassword}
            variant="outlined"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        <div className="create-new-btn-container">
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            onClick={() => handleSubmitChangePassword()}
          >
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ChangePassword;
