/* eslint-disable */
import { rolesRoutes } from 'constant/apiRoutes';
import apiClient from 'utilities/configureAxios';
class RolesAPI {
  constructor() {
    this.client = apiClient;
  }

  GetAllRoles = async () => {
    try {
      const res = await this.client.get(rolesRoutes.GET_ALL_ROLES);
      return res;
    } catch (error) {
      return error;
    }
  };
}

export default RolesAPI;
