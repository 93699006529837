/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  Snackbar,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import { initialUserName } from "utilities/userNameInitialState";
import MDSnackbar from "components/MDSnackbar";

const CreateUser = ({
  isOpenCreateModal,
  setIsOpenCreateModal,
  handleSubmitUser = () => { },
  setName = () => { },
  setUserName,
  name = "",
  userName,
  tableTitle = "",
  isCustomer = false,
  uploadedFile,
  setUploadedFile,
  isLoading = false,
  editRowData = null,
  setEditRowData = () => { },
  setRejectedDays = () => { },
  setSelectedRole = () => { },
  setIsPayment = () => { },
  rejectedDays = "",
  selectedRole = "",
  isPayment = false,
  setPlanType = () => {},
  planType = ""
}) => {
  const authUser = JSON.parse(localStorage.getItem("user"));
  const [userNameArr, setUserNameArr] = useState(initialUserName);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  function handleIsFileNameSame() {
    let uploadedFileName = uploadedFile?.name?.trim();
    if (uploadedFileName?.endsWith(".zip")) {
      uploadedFileName = uploadedFileName?.slice(0, -4);
    } else if (uploadedFileName?.endsWith(".rar")) {
      uploadedFileName = uploadedFileName?.slice(0, -4);
    }
    if (name === uploadedFileName) {
      return handleSubmitUser();
    } else {
      setSnackbarOpen(true);
      return;
    }
  }

  useEffect(() => {
    setUserNameArr(initialUserName);
  }, [isOpenCreateModal]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const style = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
  };

  useEffect(() => {
    setUserName(
      `${userNameArr?.clientName}${userNameArr?.clientCode}${userNameArr?.alphabetic}${userNameArr?.numeric}${userNameArr?.format}`
    );
  }, [userNameArr]);

  return (
    <>
      <MDSnackbar
        color={"error"}
        icon={"close"}
        title="File name does not match the user name."
        content={"The file which you have selected does not match with the User's name"}
        open={snackbarOpen}
        onClose={() => handleSnackbarClose()}
        close={() => handleSnackbarClose()}
        bgWhite
      />
      <Modal
        open={isOpenCreateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size=""
      >
        <Box sx={style}>
          <Backdrop
            sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={() => { }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <MDButton
            variant="primary"
            color="light"
            size="small"
            style={{ backgroundColor: "#fff", position: "absolute", top: "10px", right: "0" }}
            onClick={() => {
              setIsOpenCreateModal(false);
              setName("");
              setUserName("");
              setEditRowData(null);
              // if (isCustomer == true) {
              setUploadedFile(null);
              // }
              setUserNameArr(initialUserName);
              setRejectedDays("");
              setSelectedRole("");
              setPlanType("")
            }}
          >
            <Icon fontSize="small">close</Icon>
          </MDButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "1rem" }}
          >
            {tableTitle}
          </Typography>
          <div className="modal-content">
            {isCustomer == false && editRowData == null ? (
              <>
                <div className="modal-username-content">
                  <TextField
                    id="outlined-basic"
                    label="Client Name"
                    value={userNameArr?.clientName}
                    variant="outlined"
                    onChange={(e) =>
                      setUserNameArr((prevState) => ({
                        ...prevState,
                        clientName: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    value={userNameArr?.clientCode}
                    type="number"
                    label="Client Code"
                    variant="outlined"
                    onChange={(e) =>
                      setUserNameArr((prevState) => ({
                        ...prevState,
                        clientCode: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    value={userNameArr?.alphabetic}
                    label="Alphabetic"
                    type="text"
                    variant="outlined"
                    onChange={(e) =>
                      setUserNameArr((prevState) => ({
                        ...prevState,
                        alphabetic: e.target.value.replace(/[^a-zA-Z]/g, ""),
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    value={userNameArr?.numeric}
                    type="text"
                    label="Numeric"
                    variant="outlined"
                    onChange={(e) =>
                      setUserNameArr((prevState) => ({
                        ...prevState,
                        numeric: e.target.value.replace(/[eE]/g, "").replace(/[^0-9.]/g, ""),
                      }))
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    label="Format"
                    variant="outlined"
                    value={userNameArr?.format}
                    onChange={(e) =>
                      setUserNameArr((prevState) => ({
                        ...prevState,
                        format: e.target.value,
                      }))
                    }
                  />
                </div>
              </>
            ) : isCustomer == false ? (
              <TextField
                id="outlined-basic"
                label="User Name"
                value={userName}
                variant="outlined"
                onChange={(e) => setUserName(e.target.value)}
              />
            ) : null}
            <TextField
              id="outlined-basic"
              label="User Name"
              value={isCustomer == true ? name : userName}
              variant="outlined"
              onChange={(e) => {
                if (isCustomer == true) {
                  setName(e.target.value);
                }
              }}
              disabled={isCustomer == true ? false : true}
            />
            {isCustomer == true ? (
              <TextField
                id="outlined-basic"
                type="file"
                variant="outlined"
                onChange={(e) => {
                  const file = e.target.files[0];
                  const allowedExtensions = ["zip", "rar"];
                  const fileExtension = file.name.split(".").pop().toLowerCase();

                  if (allowedExtensions.includes(fileExtension)) {
                    setUploadedFile(file);
                  } else {
                    alert("Invalid file type. Please select a .zip or .rar file.");
                    e.target.value = "";
                  }
                }}
              />
            ) : null}
            {isCustomer == false ? (
              <TextField
                id="outlined-basic"
                type="file"
                variant="outlined"
                inputProps={{ accept: ".png" }}
                onChange={(e) => setUploadedFile(e.target.files[0])}
              />
            ) : null}
              {tableTitle == "ADMIN" ? (
                <TextField
                  id="outlined-basic"
                  label="Rejected Days"
                  value={rejectedDays}
                  variant="outlined"
                  placeholder="Rejected Days"
                  onChange={(e) => setRejectedDays(e.target.value)}
                />
              ) : null}
            {
              tableTitle == "MASTER FRANCHISE" || tableTitle == "REGULAR FRANCHISE" || tableTitle == "ADMIN" ? (
                <div>
                  <FormControl>
                    <RadioGroup
                    value={planType == "" ? "domestic" : planType}
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(e) => setPlanType(e.target.value)}
                    >
                      <FormControlLabel value="domestic" control={<Radio />} label="Domestic" />
                      <FormControlLabel value="international" control={<Radio />} label="International" />
                    </RadioGroup>
                  </FormControl>
                </div>
              ) : null
            }
            {tableTitle == "USER" ? (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" sx={{ lineHeight: "1rem" }}>
                  Select Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedRole}
                  label="Select Role"
                  sx={{ lineHeight: "3rem" }}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <MenuItem value={"User"}>User</MenuItem>
                  <MenuItem value={"CheckerUser"}>Checker</MenuItem>
                </Select>
              </FormControl>
            ) : null}
            {/* {tableTitle == "ADMIN" && authUser?.role?.name == "SuperAdmin" ? <div>
              <FormControlLabel control={<Checkbox onClick={(e) => setIsPayment(e.target.checked)}/>} label="Payment Integration" />
            </div> : null} */}
          </div>
          <div className="create-new-btn-container">
            <Button
              variant="contained"
              disabled={
                editRowData == null
                  ? tableTitle == "ADMIN"
                    ? userNameArr?.clientName == "" ||
                    userNameArr?.clientCode == "" ||
                    userNameArr?.alphabetic == "" ||
                    userNameArr?.numeric == "" ||
                    userNameArr?.format == "" ||
                    rejectedDays == ""
                    : tableTitle == "USER"
                      ? userNameArr?.clientName == "" ||
                      userNameArr?.clientCode == "" ||
                      userNameArr?.alphabetic == "" ||
                      userNameArr?.numeric == "" ||
                      userNameArr?.format == "" ||
                      selectedRole == ""
                      : tableTitle != "ADMIN" && isCustomer == false
                        ? userNameArr?.clientName == "" ||
                        userNameArr?.clientCode == "" ||
                        userNameArr?.alphabetic == "" ||
                        userNameArr?.numeric == "" ||
                        userNameArr?.format == ""
                        : name == "" || uploadedFile == null
                          ? true
                          : false
                  : tableTitle == "ADMIN"
                    ? userName == "" || rejectedDays == ""
                    : tableTitle == "USER"
                      ? userName == "" || selectedRole == ""
                      : tableTitle != "ADMIN" && isCustomer == false
                        ? userName == ""
                        : name == "" || uploadedFile == null
                          ? true
                          : false
              }
              sx={{ color: "#fff" }}
              onClick={() => {
                if (editRowData == null) {
                  if (tableTitle == "ADMIN") {
                    if (
                      userNameArr?.clientName != "" &&
                      userNameArr?.clientCode != "" &&
                      userNameArr?.alphabetic != "" &&
                      userNameArr?.numeric != "" &&
                      userNameArr?.format != "" &&
                      rejectedDays != ""
                    ) {
                      handleSubmitUser();
                    }
                  } else if (tableTitle == "USER") {
                    if (
                      userNameArr?.clientName != "" &&
                      userNameArr?.clientCode != "" &&
                      userNameArr?.alphabetic != "" &&
                      userNameArr?.numeric != "" &&
                      userNameArr?.format != "" &&
                      selectedRole != ""
                    ) {
                      handleSubmitUser();
                    }
                  } else if (tableTitle != "ADMIN" && isCustomer == false) {
                    if (
                      userNameArr?.clientName != "" &&
                      userNameArr?.clientCode != "" &&
                      userNameArr?.alphabetic != "" &&
                      userNameArr?.numeric != "" &&
                      userNameArr?.format != ""
                    ) {
                      handleSubmitUser();
                    }
                  } else if (isCustomer == true) {
                    if (name != "" && uploadedFile != null) {
                      handleIsFileNameSame();
                    }
                  }
                } else {
                  if (tableTitle == "ADMIN") {
                    if (userName != "" && rejectedDays != "") {
                      handleSubmitUser();
                    }
                  } else if (tableTitle == "USER") {
                    if (userName != "" && selectedRole != "") {
                      handleSubmitUser();
                    }
                  } else if (tableTitle != "ADMIN" && isCustomer == false) {
                    if (userName != "") {
                      handleSubmitUser();
                    }
                  } else if (isCustomer == true) {
                    if (name != "" && uploadedFile != null) {
                      handleIsFileNameSame();
                    }
                  }
                }
              }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreateUser;
