/* eslint-disable prettier/prettier */
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Icon,
  Pagination,
  Snackbar,
  Tab,
  TablePagination,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import styled from "styled-components";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import PlansAPI from "api/plans";
import CreatePayment from "examples/CreatePayment";
import PricingAPI from "api/pricing";
import MDSnackbar from "components/MDSnackbar";

const StyledTab = styled(Tab)`
  transition: color 0.7s ease-in;
  transition: color 0.7s ease-out;
`;

const PaymentOrder = () => {
  const loginUser = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const plansService = new PlansAPI();
  const pricingService = new PricingAPI();
  const [value, setValue] = useState(0);

  const [isYearly, setIsYearly] = useState(false);
  const [getAllPlans, setGetAllPlans] = useState([]);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    type: "",
    content: "",
  });
  const [isAvailableInternationalPlan, setIsAvailableInternationalPlan] = useState(false);

  if (
    (loginUser?.role?.name != "Master Super Admin" || loginUser?.role?.name != "Admin") &&
    loginUser?.isPaymentIntegration != 1
  ) {
    return navigate(-1);
  }
  useEffect(() => {
    (async () => {
      getAllPlansList();
    })();
  }, []);

  const getAllPlansList = async () => {
    setIsLoading(true);
    const resp = await plansService.GetPlansLists();
    if (resp?.data?.data?.plans) {
      setGetAllPlans(resp?.data?.data?.plans);
      setIsLoading(false);
      resp?.data?.data?.plans?.map((item) => {
        if (item?.isInternational == 1) {
          setIsAvailableInternationalPlan(true);
        }
      });
    } else {
      setIsLoading(false);
    }
  };

  const handleSubmitPlan = async () => {
    setIsLoading(true);

    const payload = {
      planId: editRowData?.id,
      price: editRowData?.discountPrice == 0 ? editRowData?.price : editRowData?.discountPrice,
      name: name,
      mobile: mobile,
      email: email,
    };
    const resp = await pricingService.CreatePricing(payload);

    if (resp?.data?.info?.isSuccess == true) {
      if (resp?.data?.data?.redirectUrl) {
        window.location.href = resp?.data?.data?.redirectUrl;
      }
      setIsLoading(false);
      getAllPlansList();
      setIsOpenCreateModal(false);
      setName("");
      setEmail("");
      setMobile("");
      setEditRowData(null);
    } else {
      setIsLoading(false);
      setIsOpenAlert(true);
      setErrorMsg({
        type: "error",
        content:
          resp?.message == "Network Error"
            ? resp?.message
            : resp?.response?.data?.info?.message
            ? resp?.response?.data?.info?.message
            : resp?.response?.data?.errors[0]?.msg,
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (isOpenAlert == true) {
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
    }
  }, [isOpenAlert]);

  return (
    <>
      <Backdrop
        sx={{ color: "#318BEC", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color={errorMsg?.type}
        icon={errorMsg?.type == "success" ? "check" : "close"}
        title="ADMIN"
        content={errorMsg?.content}
        open={isOpenAlert}
        onClose={() => setIsOpenAlert(false)}
        close={() => setIsOpenAlert(false)}
        bgWhite
      />
      <CreatePayment
        setIsOpenCreateModal={setIsOpenCreateModal}
        isOpenCreateModal={isOpenCreateModal}
        handleSubmitUser={handleSubmitPlan}
        setName={setName}
        name={name}
        tableTitle={"Pricing Plans"}
        isLoading={isLoading}
        email={email}
        setEmail={setEmail}
        mobile={mobile}
        setMobile={setMobile}
        editRowData={editRowData}
        setEditRowData={setEditRowData}
      />
      <DashboardLayout>
        <MDBox px={1} width="100%" mx="auto">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Grid item xs={12} sm={12} md={12}>
              <Container
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                  margin: 0,
                  maxWidth: "100% !important",
                }}
              >
                <Box textAlign="center" my={4}>
                  <Typography
                    sx={{ color: "#000", marginBottom: "20px" }}
                    variant="h4"
                    component="h1"
                    gutterBottom
                  >
                    Get Balance
                  </Typography>
                  {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Tabs value={value} onChange={handleChange} centered>
                      <StyledTab
                        label="Domestic"
                        sx={{ padding: "10px 20px", color: value == 0 && "white !important", backgroundColor: value == 0 && "#1a73e8" }}
                      />
                      {
                        isAvailableInternationalPlan && <StyledTab label="International" sx={{ padding: "10px 20px",color: value == 1 && "white !important" }} />
                      }
                  
                    </Tabs>
                  </Box> */}
                </Box>
                <Grid
                  container
                  spacing={4}
                  sx={{ justifyContent: "center", width: "100%", marginLeft: 0, gap: 6 }}
                >
                  {getAllPlans
                    // ?.filter((plan) => plan.isInternational === value)
                    ?.map((plan, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        key={index}
                        style={{ paddingLeft: 0, paddingTop: 0 }}
                      >
                        <Card>
                          <MDBox
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                            mx={2}
                            mt={-3}
                            p={2}
                            mb={1}
                            textAlign="center"
                          >
                            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  textAlign: "center",
                                }}
                              >
                                {plan?.name}
                              </div>
                            </MDTypography>
                          </MDBox>
                          <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                              <MDBox mb={2}>
                                <MDTypography sx={{ textAlign: "center", fontSize: "15px" }}>
                                  {`Get ${plan?.balance} Balance`}
                                </MDTypography>
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "18px",
                                      textAlign: "center",
                                      wordWrap: "normal",
                                    }}
                                  >
                                    <span
                                      style={
                                        plan?.discountPrice
                                          ? {
                                              color: "#ccc",
                                              textDecoration: "line-through",
                                              marginRight: "1rem",
                                              fontSize: "18px",
                                              wordWrap: "normal",
                                            }
                                          : {}
                                      }
                                    >
                                      &#8377;{plan?.price}
                                    </span>
                                    {plan?.discountPrice ? (
                                      <span
                                        style={{
                                          color: "#000",
                                          fontSize: "18px",
                                          wordWrap: "normal",
                                        }}
                                      >
                                        &#8377;{plan?.discountPrice}
                                      </span>
                                    ) : null}
                                  </div>
                                </MDTypography>
                              </MDBox>
                              <MDBox mt={4} mb={1}>
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  onClick={() => {
                                    setIsOpenCreateModal(true), setEditRowData(plan);
                                  }}
                                >
                                  Buy Now
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default PaymentOrder;
